import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import {
  formsViewActions,
  formsViewInitialState,
  formsViewReducer,
} from "../reducer";
import { useFormDesign } from "../utilities";

const FormsViewContext = createContext();

export const useFormsViewContext = () => {
  return useContext(FormsViewContext);
};

export const FormsViewContextProvider = ({ children, formDesignId }) => {
  const [formsViewState, dispatchFormsView] = useReducer(formsViewReducer, {
    ...formsViewInitialState,
  });

  const { formDesignData, formSchema } = useFormDesign(
    `form-designs/form-schema/${formDesignId}`,
    formDesignId
  );

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    Object.keys(formsViewActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          formsViewActions[key](...args)(dispatchFormsView, stateRef.current);
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = formsViewState;
  }, [formsViewState]);

  const value = useMemo(
    () => ({
      formsViewState,
      dispatchFormsView,
      actions,
      formDesignData,
      formSchema,
    }),
    [formsViewState, dispatchFormsView, actions, formDesignData]
  );

  return (
    <FormsViewContext.Provider value={value}>
      {children}
    </FormsViewContext.Provider>
  );
};
