import { Grid, IconButton, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export const CustomPagination = ({
  pageNumber,
  handlePreviousPage,
  handleNextPage,
  disableFirst,
  disableNext,
}) => {

  return (
    <Grid container padding="0.5% 1%">
      <Grid item xs={4}></Grid>
      <Grid
        item
        xs={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <IconButton disabled={disableFirst} onClick={handlePreviousPage}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography>{pageNumber}</Typography>
        <IconButton disabled={disableNext} onClick={handleNextPage}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
  );
};
