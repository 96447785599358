import "antd/dist/antd.less";
import { createSchemaField } from "@formily/react";
import { Card, Slider, Rate, Image } from "antd";
import {
  FormItem,
  DatePicker,
  Cascader,
  Editable,
  Input,
  NumberPicker,
  Switch,
  Password,
  PreviewText,
  Radio,
  Reset,
  Select,
  Space,
  Submit,
  TimePicker,
  Transfer,
  TreeSelect,
  FormGrid,
  FormLayout,
  FormTab,
  FormCollapse,
  ArrayTable,
  ArrayCards,
} from "@formily/antd";

import { fetchEmployees, fetchSections } from "./useScope";
import { Checkbox, Text, Upload } from "../pages/forms-builder/components";
import { useMemo } from "react";
import { createForm } from "@formily/core";
import { deleteFile } from "../api";

export const useSchemaField = () => {
    const form = useMemo(() => createForm(), []);
    
    const SchemaField = createSchemaField({
        components: {
          Space,
          FormGrid,
          FormLayout,
          FormTab,
          FormCollapse,
          ArrayTable,
          ArrayCards,
          FormItem,
          DatePicker,
          Checkbox,
          Cascader,
          Editable,
          Input,
          Image,
          Text,
          NumberPicker,
          Switch,
          Password,
          PreviewText,
          Radio,
          Reset,
          Select,
          Submit,
          TimePicker,
          Transfer,
          TreeSelect,
          Upload,
          Card,
          Slider,
          Rate,
        },
        scope: {
          fetchEmployees: async (field) => {
            try {
              const options = await fetchEmployees();
              field.dataSource = options;
            } catch (error) {
              console.log(error);
            } finally {
              field.loading = false;
            }
          },
          fetchSections: async (field) => {
            try {
              const options = await fetchSections();
              field.dataSource = options;
            } catch (error) {
              console.log(error);
            } finally {
              field.loading = false;
            }
          },
          
        },
      });

      // const handleUpdateFile = (file, choice) => {
      //   //Loop for keys in the form.values
      //   for (const key in form.values) {
      //     //Check if the key exist in form values
      //     if (form.values.hasOwnProperty(key)) {
      //       const array = form.values[key];
      //       //Loop through array
      //       for (let i = 0; i < array.length; i++) {
      //         //Check if that element has uid and the file.uid is equals to the element uid
      //         if (array[i]?.uid && array[i]?.uid === file?.uid) {
      //           if (choice === "ADD") {
      //             form.values[key][i] = {
      //               uid: file.uid,
      //               url: file.response.object.url,
      //               thumbUrl: file.response.object.thumbUrl,
      //               name: file.response.object.name,
      //             };
      //           }
      //           if (choice === "DELETE") {
      //             form.values[key].splice(i, 1);
      //           }
      //         }
      //       }
      //     }
      //   }
      // };

    return {SchemaField, form}
}