import { Grid, styled } from "@mui/material";

export const FavoritesContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: { xs: "auto", md: "40%" },
  display: "flex",
  justifyContent: "center",
}));

export const CardContainer = styled(Grid)(({ theme }) => ({
  height: "auto",
  display: "flex",
  justifyContent: "center",
  width: "95%",
  [theme.breakpoints.down("xs")]: {
    height: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Card = styled("div")(({ active, theme, internet }) => ({
  width: "100%",
  height: "100%", 
  background: internet
    ? active
      ? theme.palette.accent.online
      : theme.palette.accent.inactive
    : active
    ? theme.palette.accent.offline
    : theme.palette.accent.inactive,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "normal",
  fontSize: "clamp(1rem, 2vw, 1.4rem)",
  color: "#fff",
  borderRadius: 14,
  // boxShadow: `2px 2px 0px ${theme.palette.common.darkGray}`,
  cursor: "pointer",
  transition: "transform 0.3s ease",
  padding: "4%",
  "&:hover": {
    transform: "translateY(-3%)",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%", 
    height: "auto",
    marginBottom: "1rem",
    fontSize: "clamp(0.8rem, 4vw, 1.2rem)",
  },
}));

export const TextContainer = styled("div")(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box", 
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2, 
  whiteSpace: "normal", 
  width: "100%",
  height: "auto",
  lineHeight: "1.2", 
  maxHeight: "4rem", 
  fontSize: "16px"
}));

export const Favorites = ({ data, handleClickFavorite, internet }) => {
  return (
    <FavoritesContainer>
      <CardContainer container rowSpacing={1} columnSpacing={2}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            onClick={() => {
              if (data[index] !== undefined) {
                handleClickFavorite(data[index]);
              } else {
                return
              }
            }}
          >
            {index < data?.length ? (
              <Card active={true} internet={internet}>
                <TextContainer>{data[index].name}</TextContainer>
              </Card>
            ) : (
              <Card active={false}></Card>
            )}
          </Grid>
        ))}
      </CardContainer>
    </FavoritesContainer>
  );
};
