import useAxios from "../utilities/useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const getExcelReport = async (
  keyword,
  version,
  formAnswerType,
  pageSize,
  pageNumber,
  filterSubmission,
  startDate,
  endDate,
  formDesignId
) => {
  try {
    const response = await useAxios.get(`${BACKEND_DEV_URL}/form-answers/download-excel/`, {
      params: {
        keyword,
        version,
        formAnswerType,
        page: pageNumber,
        size: pageSize,
        formDesignId,
        sort: "",
        startDate,
        endDate,
        ...filterSubmission,
      },
      responseType: "arraybuffer",
    });

    // Create a Blob from the response data
    const blob = new Blob([response.data], {
      type: "application/vnd.ms-excel",
    });

    // Create a download link and trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = "Form_Answers_Report.xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};
