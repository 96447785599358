import "antd/dist/antd.less";
import React, { useEffect, useMemo, useState } from "react";
import { createForm } from "@formily/core";
import { Form, Submit, FormButtonGroup } from "@formily/antd";
import { MessageDialog, PageComponent } from "../../components";
import { Box, Typography } from "@mui/material";
import { useFormsViewContext, useGlobalContext } from "../../context";
import {
  theme,
  useAlert,
  useInternetChecker,
  useOfflineForm,
  useSchemaField,
} from "../../utilities";
import { RESET_INITIAL_STATE } from "../../constants";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { set } from "idb-keyval";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const formatDateToYMD = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const FormsView = () => {
  const { SchemaField, form } = useSchemaField();
  const userId = localStorage.getItem("id");
  const userName = localStorage.getItem("fullName");

  const { formsView } = useGlobalContext();
  const { formDesignId, formId, formName } = useParams();

  const handleGoBack = () => {
    localStorage.removeItem("form_initial_answer");
    window.history.back();
  };

  const {
    formsViewState,
    dispatchFormsView,
    actions: { submitFormAnswers, submitFormAnswersOffline },
    formDesignData,
    formSchema,
  } = useFormsViewContext();

  const { handleAlertMessage, ...alertRest } = useAlert();
  const { state } = useLocation();

  const { internet } = useInternetChecker();

  const { offlineFormAnswersFormView, isFormAlreadyAdded } = useOfflineForm(
    userId,
    internet,
    formId,
    formName
  );

  const [showDialog, setDialog] = useState(false);
  const [workingStatus, setWorkingStatus] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState();

  let formDataSchema = internet ? formSchema : state?.design?.schema;

  const qrCodeValues = internet
    ? JSON.parse(localStorage.getItem("form_initial_answer")) ?? {}
    : JSON.parse(localStorage.getItem("offline_form_initial_answer")) ?? {};

  // if (internet) {
  //   if (Object.keys(qrCodeValues)?.length > 0 && formDesignData) {
  //     const initialValues = {};
  //     Object?.keys(formDesignData?.schema?.schema?.properties)?.forEach(
  //       (key) => {
  //         initialValues[key] = qrCodeValues[key];
  //       }
  //     );

  //     console.log("initial values here ", formDesignData);

  //     form.setInitialValues(initialValues);
  //   }
  // } else {
  //   if (Object.keys(qrCodeValues)?.length > 0 && state) {
  //     const initialValues = {};
  //     Object?.keys(state?.design?.schema?.properties)?.forEach((key) => {
  //       initialValues[key] = qrCodeValues[key];
  //     });

  //     form.setInitialValues(initialValues);
  //   }
  // }
  if (internet) {
    if (Object.keys(qrCodeValues)?.length > 0 && formDesignData) {
      const gridExists = !!formDesignData?.schema?.schema?.properties?.grid;

      if (gridExists) {
        const initialValues = {};
        Object?.keys(
          formDesignData?.schema?.schema?.properties?.grid?.properties
        )?.forEach((key) => {
          initialValues[key] = qrCodeValues[key];
        });

        form.setInitialValues(initialValues);
      } else {
        console.log("STATE ", state);
        const initialValues = {};
        Object?.keys(formDesignData?.schema?.schema?.properties)?.forEach(
          (key) => {
            initialValues[key] = qrCodeValues[key];
          }
        );

        form.setInitialValues(initialValues);
      }
    }
  } else {
    if (Object.keys(qrCodeValues)?.length > 0 && state) {
      const gridExists = !!state?.design?.schema?.properties?.grid;

      if (gridExists) {
        const initialValues = {};
        Object?.keys(
          state?.design?.schema?.properties?.grid?.properties
        )?.forEach((key) => {
          initialValues[key] = qrCodeValues[key];
        });

        form.setInitialValues(initialValues);
      } else {
        const initialValues = {};
        Object?.keys(state?.design?.schema?.properties)?.forEach((key) => {
          initialValues[key] = qrCodeValues[key];
        });

        form.setInitialValues(initialValues);
      }
    }
  }

  const { form: formProps, schema } = internet
    ? formDesignData?.schema ?? {}
    : state?.design ?? {};

  const handleSuccess = () => {
    handleShowDialog();
    dispatchFormsView({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    handleAlertMessage(formsViewState.errorMessage, "error");
    dispatchFormsView({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (formsViewState.successMessage) {
      handleSuccess();
    } else if (formsViewState.errorMessage) {
      handleError();
    }
  }, [formsViewState.success, formsViewState.error]);

  useEffect(() => {
    formDataSchema = internet ? formSchema : state?.design?.schema;
  }, [formSchema]);

  const onFinish = (values) => {
    let currentDate = new Date();
    if (values.working_status === "W") {
      currentDate = new Date().toISOString();
    } else if (values.working_status === "NW") {
      currentDate = new Date(values.date).toISOString();
    } else {
      currentDate = new Date().toISOString();
    }

    const newValues =
      values.working_status === "NW"
        ? { working_status: "NW", date: currentDate }
        : { ...values, date: currentDate };

    if (internet) {
      submitFormAnswers({
        formDesignId,
        userId,
        design: newValues,
        dateCreated: currentDate,
      });
    } else {
      submitFormAnswersOffline(
        set,
        userId,
        offlineFormAnswersFormView,
        isFormAlreadyAdded,
        {
          answer: newValues,
          dateCreated: currentDate,
          dateModified: moment(new Date()).format("YYYY-MM-DD"),
          formDesign: state?.design,
          formDesignId: state?.formDesignId,
          formId: state?.id,
          formName: state?.name,
          userId: userId,
          user: {
            userId: userId,
            fullname: userName,
          },
        }
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleShowDialog = () => {
    setDialog(true);
  };

  const handleCloseDialog = () => {
    setDialog(false);
    window.location.reload(false);
  };

  return (
    <PageComponent
      showAlert={alertRest.showAlert}
      handleCloseAlert={alertRest.handleCloseAlert}
      alertSeverity={alertRest.alertSeverity}
      alertMessage={alertRest.alertMessage}
      isLoading={formsViewState.loading}
    >
      <MessageDialog
        open={showDialog}
        onClose={handleCloseDialog}
        content={
          formDesignData?.form?.successMessage ?? "Your Form Has been Submitted"
        }
        internet={internet}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          marginTop: "2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: { xs: "20px", sm: "50px", md: "100px", lg: "150px" },
            marginBottom: "75px",
          }}
        >
          <ArrowBackIcon onClick={handleGoBack} sx={{ cursor: "pointer" }} />
          <Typography
            variant="h5"
            sx={{
              marginLeft: "10px",
              fontWeight: "bold",
            }}
          >
            {formName}
          </Typography>
        </Box>

        {/* {console.log("Internet:", internet)}
        {console.log("formDataSchema:", formDataSchema)}
        {console.log(
          "track",
          Object.keys(formDataSchema.properties).length === 0
        )} */}
        {!formDataSchema ||
        Object.keys(formDataSchema.properties).length === 0 ? (
          internet ? (
            <Box>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Form is Currently Empty
              </Typography>
              <br></br>
              <Typography variant="body1">
              It looks like this form has no content. Please coordinate with the admin to create or edit the form in the Forms Builder app before proceeding.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Connection Lost
              </Typography>
              <br></br>
              <Typography variant="body1">
                To continue without interruption, download content for offline
                access.
              </Typography>
            </Box>
          )
        ) : (
          <Form
            {...formProps}
            form={form}
            onAutoSubmitFailed={onFinishFailed}
            onAutoSubmit={onFinish}
            style={{ flex: 1 }}
          >
            <SchemaField schema={formDataSchema} />
            <FormButtonGroup.FormItem>
              <Submit
                size="large"
                style={{
                  backgroundColor: internet
                    ? theme.palette.accent.online
                    : theme.palette.accent.offline,
                  borderColor: internet
                    ? theme.palette.accent.online
                    : theme.palette.accent.offline,
                  width: "100px",
                  marginTop: "2%",
                }}
              >
                Submit
              </Submit>
            </FormButtonGroup.FormItem>
          </Form>
        )}
      </Box>
    </PageComponent>
  );
};
