import { useEffect, useState } from "react";
import { get } from "idb-keyval";

export const useOfflineForm = (userId, internet, formId, formName) => {
  const [offlineFormsData, setOfflineFormsData] = useState(null);
  const [offlineFavoriteForms, setOfflineFavoriteForms] = useState(null);
  const [offlineFormAnswers, setOfflineFormAnswers] = useState(null);
  const [offlineFormAnswersFormView, setOfflineFormAnswersFormView] =
    useState(null);

  const [offlineFormPageNumber, setOfflineFormPageNumber] = useState(1);
  const [offlinePageSize, setOfflinePageSize] = useState(10);

  const [offlineFormAnswersPageNumber, setOfflineFormAnswersPageNumber] =
    useState(1);
  const [offlineFormAnswersPageSize, setOfflineFormAnswersPageSize] =
    useState(10);

  const offlineIsFirstPage = offlineFormPageNumber === 1;
  const offlineIsLastPage = offlineFormsData?.length < offlinePageSize;

  const offlineAnswerIsFirstPage = offlineFormAnswersPageNumber === 1;
  const offlineAnswerIsLastPage =
    offlineFormAnswers?.length < offlineFormAnswersPageSize;

  const isFormAlreadyAdded = (obj, list) => {
    const objString = JSON.stringify(obj);

    for (let i = 0; i < list?.length; i++) {
      const listItemString = JSON.stringify(list[i]);

      if (listItemString === objString) {
        return true;
      }
    }

    return false;
  };

  const handleOfflineFormNextPage = () => {
    setOfflineFormPageNumber(offlineFormPageNumber + 1);
  };

  const handleOfflineFormPrevPage = () => {
    setOfflineFormPageNumber(offlineFormPageNumber - 1);
  };

  const handleOfflineChangePage = (pageSize) => {
    setOfflinePageSize(pageSize);
  };

  const handleOfflineFormAnswerNextPage = () => {
    setOfflineFormAnswersPageNumber(offlineFormAnswersPageNumber + 1);
  };

  const handleOfflineFormAnswerPrevPage = () => {
    setOfflineFormAnswersPageNumber(offlineFormAnswersPageNumber - 1);
  };

  const handleOfflineAnswersChangePage = (pageSize) => {
    setOfflineFormAnswersPageSize(pageSize);
  };

  useEffect(() => {
    const handleFetchOfflineData = async () => {
      try {
        const startIndex = (offlineFormPageNumber - 1) * offlinePageSize;
        const endIndex = startIndex + offlinePageSize;

        const value = await get(`downloaded-forms-${userId}`);
        if (value) {

          const combinedArray = value.concat(offlineFavoriteForms || []);
          const uniqueArray = combinedArray.filter(
            (value, index, self) =>
              self.findIndex((v) => v?.formDesignId === value?.formDesignId) ===
              index
          );

          const paginatedData = uniqueArray.slice(startIndex, endIndex);
          setOfflineFormsData(paginatedData);
        } else {
          setOfflineFormsData([]);
        }
      } catch (e) {
        console.error(e);
      }
    };

    handleFetchOfflineData();
  }, [internet, offlineFormPageNumber, offlinePageSize, offlineFavoriteForms]);

  useEffect(() => {
    const handleFetchFavoriteOfflineForms = async () => {
      try {
        const value = await get(`favorite-forms-${userId}`);
        if (value) {
          setOfflineFavoriteForms(value);
        } else {
          setOfflineFavoriteForms([]);
        }
      } catch (e) {
        console.error(e);
      }
    };

    handleFetchFavoriteOfflineForms();
  }, [internet]);

  useEffect(() => {
    const handleFetchOfflineFormAnswers = async () => {
      const startIndex =
        (offlineFormAnswersPageNumber - 1) * offlineFormAnswersPageSize;
      const endIndex = startIndex + offlineFormAnswersPageSize;

      try {
        get(`form-answers-user-${userId}`).then((value) => {
          if (value) {

            const filteredData = value.filter(
              (entry) =>
                entry.formId === parseInt(formId) && entry.formName === formName
            );

            const paginatedData = filteredData.slice(startIndex, endIndex);

            setOfflineFormAnswers(paginatedData);
          } else {
            setOfflineFormAnswers([]);
          }
        });
      } catch (e) {
        console.error(e);
      }
    };

    handleFetchOfflineFormAnswers();
  }, [internet, offlineFormAnswersPageNumber, offlineFormAnswersPageSize]);

  useEffect(() => {
    const handleFetchOfflineFormAnswersFormView = async () => {
      try {
        get(`form-answers-user-${userId}`).then((value) => {
          if (value) {
            setOfflineFormAnswersFormView(value);
          } else {
            setOfflineFormAnswersFormView([]);
          }
        });
      } catch (e) {
        console.error(e);
      }
    };

    handleFetchOfflineFormAnswersFormView();
  }, [internet]);

  return {
    isFormAlreadyAdded,
    offlineFormsData,
    offlineFavoriteForms,
    offlineFormAnswers,
    offlineFormPageNumber,
    handleOfflineFormNextPage,
    handleOfflineFormPrevPage,
    offlinePageSize,
    handleOfflineChangePage,
    offlineIsFirstPage,
    offlineIsLastPage,
    handleOfflineFormAnswerNextPage,
    handleOfflineFormAnswerPrevPage,
    handleOfflineAnswersChangePage,
    offlineFormAnswersPageSize,
    offlineFormAnswersPageNumber,
    offlineAnswerIsFirstPage,
    offlineAnswerIsLastPage,
    offlineFormAnswersFormView,
  };
};
