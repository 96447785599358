import { useState } from "react";

export const useDialog = () => {
    const [show, setShow] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    
    const handleAddDialogData = (data) => {
        setDialogData(data);
        setShow(true);
    }

    const handleCloseDialog = () => {
        setShow(false);
        setDialogData(null);
    }

    return {
        show,
        dialogData,
        handleAddDialogData,
        handleCloseDialog
    }

}