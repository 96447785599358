import useAxios from "../utilities/useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const updateFormFavorite = async (data) => {
  return useAxios
    .post(`${BACKEND_DEV_URL}/forms/toggle-favorite`, data)
    .catch((error) => console.error(error));
};

export const getFormDesign = async (id) => {
  return useAxios
    .get(`${BACKEND_DEV_URL}/form-designs/form-schema/${id}`)
    .catch((error) => console.error(error));
};

export const checkUserFormAccess = async (params) => {
  return useAxios
    .get(`${BACKEND_DEV_URL}/form-designs/check-form-schema-access`, {
      params: params,
    })
    .catch((error) => console.error(error));
};

export const updateOfflineForms = async (data) => {
  return useAxios
    .post(`${BACKEND_DEV_URL}/form-designs/update-offline-forms`, data)
    .catch((error) => console.error(error));
};
