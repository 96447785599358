import { FormContextProvider } from "../context";
import { FormSubmission, Forms } from "../pages";
import SendIcon from "@mui/icons-material/Send";

export const routes = [
  {
    path: "/",
    component: (
      <FormContextProvider>
        <Forms />
      </FormContextProvider>
    ),
    text: "Forms",
    icon: <SendIcon color="#b9b6a7" />,
    role: "ALL",
  },

  {
    path: "/form-submissions/:formDesignId/:formName/:formVersion/:formId",
    component: <FormSubmission />,
    text: "Form Submissions",
    icon: <SendIcon color="#b9b6a7" />,
    role: "SUPERADMIN",
  },
];
