import { createTheme, alpha, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      offwhite: "#F5F5F5",
      darkGray: "#6d7073",
      lightGrey: "#C0C0C0"
    },
    primary: {
      light: "#6de3bc",
      main: "#62B1D2",
      dark: "#1a6b50",
    },
    secondary: {
      light: "#52a6e3",
      main: "#1986d4",
      dark: "#07273d",
    },
    accent: {
      main: "#62B1D2",
      secondary: "#379F86",
      tertiary: "#398E0C",
      red: "#D14B56",
      darkBlue: "#34516F",
      blueGreen: "#0C3D50",
      grayBlue: "#587483",
      offline: "#62B1D2", 
      // offline: "#2F2FA2", 2nd option
      // offline: "#8D8741", 3rd option
      online: "#009688",
      inactive: "rgb(180, 180, 180)"
    },
    error: {
      light: "#ff583d",
      main: "#eb0910",
      dark: "#a4060b",
    },
    size: {
      primary: "16px",
    },
    warning: {
      light: "#ffb758",
      main: "#ffa62f",
      dark: "#b27420",
    },
    success: {
      light: "#5cc966",
      main: "#34bc40",
      dark: "#24832c",
    },
    text: {
      textPrimary: alpha("#F2F9FE", 0.6),
    },
    typography: {
      fontFamily: ["poppins", "sans-serif"].join(","),
    },
    overrides: {
      MuiButton: {
        label: {
          color: "#fff",
        },
      },
      MuiHeaders: {
        label: {
          color: "red",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Human Resource", "sans-serif"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (param) => ({
        body: {
          bodybackground: "red",
          scrollbarColor: "#6b6b6b rgba(0, 0, 0, 0)",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: 12,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 4,
            backgroundColor: "#d3d3d3",
            minHeight: 24,
            border: "2px solid rgba(0, 0, 0, 0)",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        },
      }),
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        color: "#fff",
      },
      containedPrimary: {
        color: "#fff",
        backgroundColor: "#06324B",
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export { theme };
