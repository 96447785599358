import { CssBaseline, ThemeProvider } from "@mui/material";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "./constants";
import { theme, useInternetChecker } from "./utilities";

import { GlobalContextProvider } from "./context";
import { PageWrapper, Footer } from "./components";
import { MainFormsView } from "./pages";

function App() {
  const { internet } = useInternetChecker();

  if (internet && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.log('Service Worker registration failed:', error);
        });
    });
  }

  return (
    <CssBaseline>
      <div className="App">
        <GlobalContextProvider>
          <Router>
            <PageWrapper>
              <ThemeProvider theme={theme}>
                <Routes>
                  {routes.map((item) => (
                    <Route
                      key={item}
                      path={item.path}
                      element={item.component}
                    />
                  ))}
                  <Route
                    path="/forms-view/:formDesignId/:formId/:formName"
                    element={
                      <MainFormsView/>
                    }
                  />
                </Routes>
                {/* <Footer /> */}
              </ThemeProvider>
            </PageWrapper>
          </Router>
        </GlobalContextProvider>
      </div>
    </CssBaseline>
  );
}

export default App;
