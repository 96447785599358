import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import moment from "moment";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DialogText = styled(DialogContentText)(({}) => ({
  color: "#000",
  margin: 4,
}));

const FormDialogTitle = styled(DialogTitle)(({ theme, internet }) => ({
  padding: "2.5% 2.5%",
  background: internet ? theme.palette.accent.online : theme.palette.accent.offline,
  color: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ToolTipDialog = ({ data, open, handleClose, internet }) => {
  return (
    <React.Fragment>
      <BootstrapDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "30vw",
            maxWidth: "90vw", 
            minHeight: "30vh",
            maxHeight: "80vh", 
            overflowY: "auto",
            borderRadius: 10,
            // overflow: "hidden",
          },
        }}
      >
        <FormDialogTitle internet={internet}>
          Additional Information
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
        </FormDialogTitle>
        <DialogContent dividers style={{ width: "100%" }}>
          <DialogText>
            <span style={{ fontWeight: "bolder" }}>Description:</span>{" "}
            {data.description}
          </DialogText>
          <DialogText>
            <span style={{ fontWeight: "bolder" }}>Version:</span>{" "}
            {data.version.split(" ")[1]}
          </DialogText>
          <DialogText>
            <span style={{ fontWeight: "bolder" }}>Date Created:</span>{" "}
            {moment(data.dateCreated).format("MMM DD, YYYY")}
          </DialogText>
          <DialogText>
            <span style={{ fontWeight: "bolder" }}>Date Modified:</span>{" "}
            {moment(data.dateModified).format("MMM DD, YYYY")}
          </DialogText>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};
