import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Button, DialogActions, Grid, styled } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import { ButtonText, StandardButton } from "../styles/StandardButton";

const DialogContainer = styled(Dialog)(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const MessageDialog = ({ open, onClose, title, content, internet }) => {
  let navigate = useNavigate();
  return (
    <DialogContainer open={open} onClose={onClose}>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          padding="2% 2%"
          borderRadius="8px"
          rowGap={0}
        >
          <Grid item xs={12}>
            <CheckCircleOutlineIcon
              sx={{ color: "#379F86", fontSize: "100px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{content}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", gap: 3 }}>
        <StandardButton
          onClick={() => {
            navigate("/");
          }}
        >
        <ButtonText>Go to Dashboard</ButtonText>  
        </StandardButton>
        {internet ? 
        <StandardButton onClick={onClose}>
                <ButtonText>Answer Again</ButtonText>
        </StandardButton> : null}
      </DialogActions>
    </DialogContainer>
  );
};
