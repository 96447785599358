import useAxios from "../utilities/useAxios";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const submitAnswer = async (data) => {
  return useAxios
    .post(`${BACKEND_DEV_URL}/form-answers`, data)
    .catch((error) => console.error(error));
};

export const syncFormAnswers = async (data) => {
  return useAxios
    .post(`${BACKEND_DEV_URL}/form-answers/sync-answers`, data)
    .catch((error) => console.error(error));
};

export const requestFormAccess = async (data) => {
  return useAxios
    .post(`${BACKEND_DEV_URL}/form-viewer`, data)
    .catch((error) => console.error(error));
}