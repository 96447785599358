import { Button, Grid, IconButton, Typography, styled } from "@mui/material";
import {
  ButtonText,
  CustomVersion,
  FormSubmissionTable,
  PageComponent,
  PageSize,
  SearchField,
  StandardButton,
} from "../../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  useAlert,
  useAlternateData,
  useForm,
  useInternetChecker,
  useOfflineForm,
} from "../../../utilities";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useFormSubmissionContext } from "../../../context";
import { useEffect, useState } from "react";
import { RESET_INITIAL_STATE, API_PENDING } from "../../../constants";
import { FormAnswerFilter } from "./form-answer-filter/FormAnswerFilter";
import { getExcelReport } from "../../../api";
import { Box } from "@mui/material";




const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1% 3% 0 3%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "1% 1% 0 1%",
  },
}));

const FormTitle = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto",
    minWidth: "100px",
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
  },
}));

const NavigationTypography = styled(Typography)(
  ({ theme, isSelected, internet }) => ({
    borderBottom: isSelected
      ? internet
        ? `8px solid #1B9A92`
        : `8px solid ${theme.palette.accent.offline}`
      : "none",
    paddingBottom: theme.spacing(0.5),
    textAlign: "left",
    fontWeight: isSelected ? "bolder" : "none",
  })
);

const flattenProperties = (properties) => {
  const result = [];

  const traverse = (obj, parentKey = "") => {
    for (const key in obj) {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      const property = obj[key];

      if (property.type === "void" && property.properties) {
        traverse(property.properties, currentKey);
      } else {
        result.push({
          id: currentKey,
          ...property,
        });
      }
    }
  };

  traverse(properties);
  return result;
};

export const FormAnswer = ({ formName, formId }) => {
  const userId = localStorage.getItem("id");

  const { handleAlertMessage, ...alertRest } = useAlert();
  const { state } = useLocation();
  const { internet } = useInternetChecker();

  const { formName: paramFormName, formId: paramFormId } = useParams();

  const {
    offlineFormAnswers,
    handleOfflineFormAnswerNextPage,
    handleOfflineFormAnswerPrevPage,
    handleOfflineAnswersChangePage,
    offlineFormAnswersPageSize,
    offlineFormAnswersPageNumber,
    offlineAnswerIsFirstPage,
    offlineAnswerIsLastPage,
    offlineIsFirstPage,
    offlineIsLastPage,
  } = useOfflineForm(userId, internet, paramFormId, paramFormName);

  const {
    formAnswerData,
    handleAddOfflineFormAnswersData,
    handleAddOnlineFormAnswersData,
  } = useAlternateData();
  let navigate = useNavigate();

  const {
    formSubmissionState,
    dispatchFormSubmission,
    formAnswersData,
    mutateFormAnswersData,
    optimizedSearchFormAnswers,
    pageNumber,
    pageSize,
    version,
    keyword,
    formAnswerType,
    filterSubmission,
    startDate,
    endDate,
    formDesignId,
    formVersions,
    formDesignProperties,
    optimizedSearchFormProperties,
    formAccessData,
    actions: { requestFormAccess },
  } = useFormSubmissionContext();

  const [selectedNavigation, setSelectedNavigation] = useState(formAnswerType);

  const {
    showModal: showFilter,
    handleCloseModal: handleCloseFilter,
    handleAddData: handleShowFilter,
  } = useForm();

  const properties = internet
    ? formDesignProperties?.schema?.schema?.properties
    : state?.design?.schema?.properties;

  const flattenedProperties = properties ? flattenProperties(properties) : [];

  const TableColumnSchema = flattenedProperties
    .sort((a, b) => a["x-index"] - b["x-index"])
    .filter((property) => property["x-component"] !== "Image")
    .filter(
      (property) =>
        ![
          "temperature_status",
          "left_side_pressure_status",
          "right_side_pressure_status",
          "date",
        ].includes(property.name)
    )
    .map((property) => {
      return { id: property.name ?? property.id, label: property.title };
    });

  const handleNavigateBack = () => {
    navigate("/");
  };

  const handleSelectedNavigation = (value) => {
    setSelectedNavigation(value);
  };

  const handleChangePageSize = (pageSize) => {
    dispatchFormSubmission({ type: API_PENDING });
    optimizedSearchFormAnswers(
      keyword,
      version,
      formAnswerType,
      pageSize,
      pageNumber,
      filterSubmission,
      startDate,
      endDate,
      formDesignId
    );
  };

  const handleChangePage = (pageNumber) => {
    dispatchFormSubmission({ type: API_PENDING });
    optimizedSearchFormAnswers(
      keyword,
      version,
      formAnswerType,
      pageSize,
      pageNumber,
      filterSubmission,
      startDate,
      endDate,
      formDesignId
    );
  };

  const handleSearchKeyword = (keyword) => {
    optimizedSearchFormAnswers(
      keyword,
      version,
      formAnswerType,
      pageSize,
      1,
      filterSubmission,
      startDate,
      endDate,
      formDesignId
    );
  };

  const handleSubmitFilter = (filterValues, startDate, endDate) => {
    optimizedSearchFormAnswers(
      keyword,
      version,
      formAnswerType,
      pageSize,
      1,
      filterValues,
      startDate,
      endDate,
      formDesignId
    );

    handleCloseFilter();
  };

  const handleChangeVersion = (value) => {
    optimizedSearchFormAnswers(
      keyword,
      value.version,
      formAnswerType,
      pageSize,
      1,
      filterSubmission,
      startDate,
      endDate,
      value.id
    );

    optimizedSearchFormProperties(value.id);
  };

  const handleClearFilter = () => {
    optimizedSearchFormAnswers(
      "",
      version,
      formAnswerType,
      pageSize,
      pageNumber,
      {},
      null,
      null,
      formDesignId
    );
  };

  const handleDownloadExcel = () => {
    getExcelReport(
      keyword,
      version,
      formAnswerType,
      pageSize,
      pageNumber,
      filterSubmission,
      startDate,
      endDate,
      formDesignId
    );
  };

  useEffect(() => {
    if (internet) handleAddOnlineFormAnswersData(formAnswersData);
    else handleAddOfflineFormAnswersData(offlineFormAnswers);
    dispatchFormSubmission({ type: RESET_INITIAL_STATE });
  }, [internet, formAnswersData, offlineFormAnswers]);

  useEffect(() => {
    if (formSubmissionState.successMessage) {
      handleAlertMessage(formSubmissionState.successMessage, "success");
    } else if (formSubmissionState.errorMessage) {
      handleAlertMessage(formSubmissionState.errorMessage, "error");
    }
  }, [formSubmissionState.success, formSubmissionState.error]);

  return (
    <PageComponent
      showAlert={alertRest.showAlert}
      handleCloseAlert={alertRest.handleCloseAlert}
      alertSeverity={alertRest.alertSeverity}
      alertMessage={alertRest.alertMessage}
      isLoading={internet ? formSubmissionState.loading : false}
    >
      <GridContainer>
        <FormTitle
          sx={{
            marginLeft: { xs: "0px", sm: "-10px", md: "-15px", lg: "-20px" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleNavigateBack}>
              <ArrowBackIcon sx={{ color: "black" }} />
            </IconButton>
            <Typography
              fontSize="24px"
              fontWeight="bolder"
              sx={{ marginLeft: "8px" }}
            >
              {formName}
            </Typography>
          </Box>
        </FormTitle>
      </GridContainer>
      <GridContainer container gap={2}>
        <FirstColumn
          sx={{
            marginLeft: { xs: "0px", sm: "-10px", md: "-15px", lg: "-20px" },
          }}
        >
          {internet ? (
            <SearchField search={handleSearchKeyword} keyword={keyword} />
          ) : null}
          <PageSize
            pageSizeValue={pageSize}
            handleChange={handleChangePageSize}
            internet={internet}
            offlinePageSizeValue={offlineFormAnswersPageSize}
            handleChangeOffline={handleOfflineAnswersChangePage}
          />
          {internet ? (
            <CustomVersion
              data={formVersions ?? []}
              handleChange={handleChangeVersion}
              defaultValue={{ id: formDesignId, version: version }}
            />
          ) : null}
          <StandardButton onClick={handleShowFilter}>
            <ButtonText>Filter</ButtonText>
          </StandardButton>
          {internet ? (
            <StandardButton onClick={handleClearFilter}>
              <ButtonText>Clear Filter</ButtonText>
            </StandardButton>
          ) : null}
        </FirstColumn>
        {internet ? (
          <SecondColumn item xs={12} sm={3} gap={1}>
            <StandardButton onClick={handleDownloadExcel}>
              <ButtonText>Export</ButtonText>
            </StandardButton>
          </SecondColumn>
        ) : null}
      </GridContainer>
      <Grid container gap={3} margin="1% 0% 0% 2%">
        <NavigationTypography
          internet={internet}
          onClick={() => {
            handleSelectedNavigation("MY_ANSWERS");
            optimizedSearchFormAnswers(
              keyword,
              version,
              "MY_ANSWERS",
              pageSize,
              pageNumber,
              filterSubmission,
              startDate,
              endDate,
              formDesignId
            );
          }}
          isSelected={selectedNavigation === "MY_ANSWERS"}
        >
          My Submission
        </NavigationTypography>
        {internet ? (
          <NavigationTypography
            internet={internet}
            onClick={() => {
              handleSelectedNavigation("ALL_ANSWERS");
              optimizedSearchFormAnswers(
                keyword,
                version,
                "ALL_ANSWERS",
                pageSize,
                pageNumber,
                filterSubmission,
                startDate,
                endDate,
                formDesignId
              );
            }}
            isSelected={selectedNavigation === "ALL_ANSWERS"}
          >
            All Submission
          </NavigationTypography>
        ) : null}
      </Grid>
      <Grid container>
        <FormSubmissionTable
          data={formAnswerData}
          columnSchema={TableColumnSchema}
          loading={formSubmissionState.loading}
          pageNumber={pageNumber}
          handleChangePage={handleChangePage}
          offlineFormAnswersPageNumber={offlineFormAnswersPageNumber}
          offlineAnswerFirstPage={offlineAnswerIsFirstPage}
          offlineAnswerLastPage={offlineAnswerIsLastPage}
          handleOfflineFormNextPage={handleOfflineFormAnswerNextPage}
          handleOfflineFormPrevPage={handleOfflineFormAnswerPrevPage}
          selectedNavigation={selectedNavigation}
          formAccessData={formAccessData}
          requestFormAccess={requestFormAccess}
          formId={formId}
          pagination={formAnswerData}
        />
      </Grid>

      {showFilter && (
        <FormAnswerFilter
          open={showFilter}
          onClose={handleCloseFilter}
          componentSchema={properties}
          filterValues={filterSubmission}
          handleSubmitFilter={handleSubmitFilter}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </PageComponent>
  );
};
