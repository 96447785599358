import {
  ListItemText,
  Typography,
  ListItem,
  IconButton,
  Grid,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { NavLink } from "react-router-dom";
import { theme, useInternetChecker } from "../../utilities";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { routes } from "../../constants";
import { useLocation } from "react-router-dom";
import AddHomeIcon from "@mui/icons-material/AddHome";
import LogoutIcon from "@mui/icons-material/Logout";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const MenuStyles = styled(Grid)(() => ({
  display: "flex",
  marginTop: "8%",
  flexDirection: "column",
  justifyContent: "center",
}));

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: "none !important",
  color: "white",
  fontSize: theme.palette.size.primary,
}));

const Title = styled(Typography)(() => ({
  paddingTop: 10,
  fontWeight: "bold",
  fontSize: 17,
  textTransform: "uppercase",
}));

const StyledListItem = styled(ListItem)((props) => ({
  "&:hover": {
    background: "#2F4F4F",
  },
  background: props.currentLocation
    ? props.internet
      ? `${"#009688"}`
      : theme.palette.accent.offline
    : `none`,
}));

const LinkIcon = styled(IconButton)(() => ({
  color: "white",
}));

export const Drawer = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const location = useLocation();
  const { internet } = useInternetChecker();

  const publicRoutes = routes.filter((item) => item.role !== "SUPERADMIN");

  return (
    <MuiDrawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          background: "#222D39",
          color: "#fff",
        },
        display: "block",
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <LinkIcon onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <FormatIndentDecreaseIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </LinkIcon>
      </DrawerHeader>
      <Title>Forms</Title>
      <MenuStyles component="nav">
        <StyledNavLink
          onClick={() => {
            alert("WORK IN PROGRESS");
          }}
        >
          <StyledListItem>
            <ListItemText>
              <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                <AddHomeIcon color="#fff" /> YAS Dashboard
              </Typography>
            </ListItemText>
          </StyledListItem>
        </StyledNavLink>

        {publicRoutes.map((item, index) => (
          <StyledNavLink to={item.path} key={index}>
            <StyledListItem
              button
              currentLocation={item.path === location.pathname}
              internet={internet}
            >
              <ListItemText>
                <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                  {item.icon} {item?.text}
                </Typography>
              </ListItemText>
            </StyledListItem>
          </StyledNavLink>
        ))}

        <StyledNavLink
          onClick={() => {
            const baseUrl = window.location.origin;
            const pdfUrl = `${baseUrl}/assets/user_manual_forms_app.pdf`;
            window.open(pdfUrl, "_blank");
          }}
        >
          <StyledListItem>
            <ListItemText>
              <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                <ImportContactsIcon color="#fff" /> User Manual
              </Typography>
            </ListItemText>
          </StyledListItem>
        </StyledNavLink>

        <StyledNavLink
          onClick={() => {
            alert("WORK IN PROGRESS");
          }}
        >
          <StyledListItem>
            <ListItemText>
              <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                <LogoutIcon color="#fff" /> Logout
              </Typography>
            </ListItemText>
          </StyledListItem>
        </StyledNavLink>
      </MenuStyles>
    </MuiDrawer>
  );
};
