import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import {
  formSubmissionActions,
  formSubmissionInitialState,
  formSubmissionReducer,
} from "../reducer";
import {
  useCheckFormAccess,
  useFormDesignProperties,
  useFormSubmissionsData,
  useFormVersionsData,
  useInternetChecker,
} from "../utilities";
import { RESET_INITIAL_STATE } from "../constants";

const FormSubmissionContext = createContext();

export const useFormSubmissionContext = () => {
  return useContext(FormSubmissionContext);
};

export const FormSubmissionContextProvider = ({
  children,
  designId,
  formVersion,
  formId,
}) => {
  const { internet } = useInternetChecker();
  const {
    formAnswersData,
    mutateFormAnswersData,
    optimizedSearchFormAnswers,
    pageNumber,
    pageSize,
    version,
    keyword,
    formAnswerType,
    filterSubmission,
    startDate,
    endDate,
    formDesignId,
  } = useFormSubmissionsData(`form-answers/search?`, formVersion, designId, internet);

  const userId = localStorage.getItem("id");

  const { formVersionsData } = useFormVersionsData(
    `form-designs/versions/${formId}`
  );

  const { formDesignProperties, optimizedSearchFormProperties } =
    useFormDesignProperties(`form-designs/form-schema`, formDesignId);

  const { formAccessData } = useCheckFormAccess(
    `form-viewer/check-form-access`,
    userId,
    formDesignId
  );

  useEffect(() => {
    mutateFormAnswersData();
    dispatchFormSubmission({type: RESET_INITIAL_STATE})
  }, [
    pageNumber,
    pageSize,
    version,
    keyword,
    formAnswerType,
    filterSubmission,
    startDate,
    endDate,
    formDesignId,
  ]);

  const formVersions = formVersionsData?.content.map((item) => ({
    id: item.id,
    version: item.version,
  }));

  const [formSubmissionState, dispatchFormSubmission] = useReducer(
    formSubmissionReducer,
    {
      ...formSubmissionInitialState,
    }
  );

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    Object.keys(formSubmissionActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          formSubmissionActions[key](...args)(
            dispatchFormSubmission,
            stateRef.current
          );
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = formSubmissionState;
  }, [formSubmissionState]);

  const value = useMemo(
    () => ({
      formSubmissionState,
      dispatchFormSubmission,
      actions,
      formAnswersData,
      mutateFormAnswersData,
      optimizedSearchFormAnswers,
      pageNumber,
      pageSize,
      version,
      keyword,
      formAnswerType,
      filterSubmission,
      startDate,
      endDate,
      formDesignId,
      formVersions,
      formVersionsData,
      formDesignProperties,
      optimizedSearchFormProperties,
      formAccessData,
    }),
    [
      formSubmissionState,
      dispatchFormSubmission,
      actions,
      formAnswersData,
      mutateFormAnswersData,
      optimizedSearchFormAnswers,
      pageNumber,
      pageSize,
      version,
      keyword,
      formAnswerType,
      filterSubmission,
      startDate,
      endDate,
      formDesignId,
      formVersions,
      formVersionsData,
      formDesignProperties,
      optimizedSearchFormProperties,
      formAccessData,
    ]
  );

  return (
    <FormSubmissionContext.Provider value={value}>
      {children}
    </FormSubmissionContext.Provider>
  );
};
