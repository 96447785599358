import useSWR from "swr";
import useAxios from "./useAxios";
import { useState, useEffect, useCallback } from "react";

const BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_URL;

export const useFormsData = (url) => {
  const [formsData, setFormsData] = useState([]);
  const [formsPageNumber, setFormsPageNumber] = useState(1);
  const [formsPageSize, setFormsPageSize] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  // const [formsSection, setFormsSection] = useState("");
  const [sort, setSorting] = useState("date_modified,desc");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: formsPageNumber,
          size: formsPageSize,
          keyword: searchKeyword,
          sort: sort,
          // section: formsSection
        },
      });
      return response.data.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (keyword, pageNumber, pageSize, sorting) => {
    setFormsPageNumber(pageNumber);
    setFormsPageSize(pageSize);
    setSearchKeyword(keyword);
    setSorting(sorting);
    // setFormsSection(filteredSection)

    useAxios
      .get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
          sort: sorting,
        },
      })
      .then((res) => res.data)
      .then((json) => setFormsData(json?.object ?? []));
  };

  const optimizedSearchForms = useCallback(debounce(handleChange), []);

  const { data: forms, mutate: mutateForms, error } = useSWR(`${url}`, fetcher);

  useEffect(() => {
    mutateForms();
  }, [formsPageNumber, formsPageSize, searchKeyword, sort]);

  return {
    formsData,
    forms,
    searchKeyword,
    formsPageNumber,
    formsPageSize,
    sort,
    optimizedSearchForms,
    mutateForms,
    error,
  };
};

export const useFormsFavoriteData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };
  const { data: formsFavoriteData, mutate: mutateFormsFavoriteData } = useSWR(
    url,
    fetcher
  );

  return { formsFavoriteData, mutateFormsFavoriteData };
};

export const useSectionsData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: sectionsData } = useSWR(url, fetcher);

  return { sectionsData };
};

export const useFormSubmissionsData = (
  url,
  formVersion,
  designId,
  internet
) => {
  const [keyword, setKeyword] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [version, setVersion] = useState(formVersion);
  const [formAnswerType, setFormAnswerType] = useState("MY_ANSWERS");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formDesignId, setFormDesignId] = useState(designId);

  const [filterSubmission, setFilterSubmission] = useState({});

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          keyword,
          version,
          formAnswerType,
          page: pageNumber,
          size: pageSize,
          formDesignId,
          sort: "",
          startDate,
          endDate,
          ...filterSubmission,
        },
      });
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (
    keyword,
    version,
    formAnswerType,
    pageSize,
    pageNumber,
    filterSubmission,
    startDate,
    endDate,
    formDesignId
  ) => {
    setKeyword(keyword);
    setPageSize(pageSize);
    setPageNumber(pageNumber);
    setVersion(version);
    setFormAnswerType(formAnswerType);
    setFilterSubmission(filterSubmission);
    setStartDate(startDate);
    setEndDate(endDate);
    setFormDesignId(formDesignId);
    useAxios
      .get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          keyword,
          version,
          formAnswerType,
          page: pageNumber,
          size: pageSize,
          formDesignId,
          sort: "",
          startDate,
          endDate,
          ...filterSubmission,
        },
      })
      .then((res) => res.data);
  };

  const optimizedSearchFormAnswers = useCallback(debounce(handleChange), []);

  const { data: formAnswersData, mutate: mutateFormAnswersData } = useSWR(
    url,
    fetcher,
    { revalidateOnFocus: false }
  );

  // useEffect(() => {
  //   mutateFormAnswersData();
  // }, [
  //   pageSize,
  //   pageNumber,
  //   version,
  //   formAnswerType,
  //   keyword,
  //   filterSubmission,
  //   startDate,
  //   endDate,
  //   formDesignId,
  //   internet,
  // ]);

  return {
    formAnswersData,
    mutateFormAnswersData,
    optimizedSearchFormAnswers,
    pageNumber,
    pageSize,
    version,
    keyword,
    formAnswerType,
    filterSubmission,
    startDate,
    endDate,
    formDesignId,
  };
};

export const useFormVersionsData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          page: 0,
          size: 9999,
        },
      });
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: formVersionsData } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
  });

  return { formVersionsData };
};

export const useAllFormSubmissionsData = (url, pageSize) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: allFormAnswersData, mutate: mutateAllFormAnswersData } = useSWR(
    url,
    fetcher
  );

  return { allFormAnswersData, mutateAllFormAnswersData };
};

export const useFormDesignProperties = (url, id) => {
  const [formSchemaId, setDesignId] = useState(id);

  const fetcher = async () => {
    try {
      const response = await useAxios.get(
        `${BACKEND_DEV_URL}/${url}/${formSchemaId}`
      );
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (formDesignId) => {
    setDesignId(formDesignId);
    useAxios
      .get(`${BACKEND_DEV_URL}/${url}/${formDesignId}`)
      .then((res) => res.data);
  };

  const optimizedSearchFormProperties = useCallback(debounce(handleChange), []);

  const { data: formDesignProperties, mutate: mutateFormDesignProperties } =
    useSWR(url, fetcher, { revalidateOnFocus: false });

  useEffect(() => {
    mutateFormDesignProperties();
  }, [formSchemaId]);

  return {
    formDesignProperties,
    mutateFormDesignProperties,
    optimizedSearchFormProperties,
    formSchemaId,
  };
};

export const useCheckFormAccess = (url, userId, formDesignId) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`, {
        params: {
          formDesignId,
          userId,
        },
      });
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: formAccessData } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
  });

  return { formAccessData };
};

export const useFormDesign = (url, formDesignId) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${BACKEND_DEV_URL}/${url}`, {});
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: formDesignData } = useSWR(url, fetcher);

  const formSchema = formDesignData?.schema.schema;
  for (const key in formSchema?.properties) {
    if (formSchema.properties[key]["x-component"] === "Upload") {
      formSchema.properties[key]["x-component-props"][
        "action"
      ] = `${BACKEND_DEV_URL}/files/upload/${formDesignId}`;
      formSchema.properties[key]["x-component-props"][
        "delete"
      ] = `${BACKEND_DEV_URL}/files/delete-file`;
    }
  }

  return { formDesignData, formSchema };
};
