/* eslint-disable no-throw-literal */
import { del, get } from "idb-keyval";
import {
  checkUserFormAccess,
  getFormDesign,
  syncFormAnswers,
  updateFormFavorite,
  updateOfflineForms,
} from "../api";
import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  RESET_INITIAL_STATE,
} from "../constants";

export const formInitialState = {
  loading: false,
  error: false,
  errorType: "",
  errorMessage: "",
  success: false,
  successMessage: "",
  pageForms: [],
  result: {},
  resultType: "",
};

export const formActions = {
  updateFavorite: (data) => async (dispatch) => {
    dispatch({ type: API_PENDING });
    const payload = {
      formId: data.id,
      userId: localStorage.getItem("id"),
      favorite: !data.favorite,
    };

    try {
      await updateFormFavorite(payload).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Succesfully Updated Form Favorite`,
            data: response?.data?.object,
            type: "UPDATE_FORM_FAVORITE",
          },
        });
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  checkFormDesign: (data) => async (dispatch) => {
    try {
      await getFormDesign(data).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Succesfully Get Form Design`,
            data: response?.data?.object,
            type: "GET_FORM_DESIGN",
          },
        });
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  checkFormDesignOffline: (offlineFormData) => async (dispatch) => {
    try {
      if (offlineFormData) {
        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Succesfully Get Form Design`,
            data: offlineFormData,
            type: "GET_FORM_DESIGN",
          },
        });
      } else {
        dispatch({ type: API_FAILURE, payload: "Form not available." });
      }
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  checkFormDesignOfflineZebra: (offlineFormData) => async (dispatch) => {
    try {
      if (offlineFormData) {
        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Succesfully Get Form Design`,
            data: offlineFormData,
            type: "GET_FORM_DESIGN_ZEBRA",
          },
        });
      } else {
        dispatch({ type: API_FAILURE, payload: "Form not available." });
      }
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  checkFormAccess: (formDesignId, checkFormDesign) => async (dispatch) => {
    try {
      const params = {
        formDesignId: formDesignId,
      };
      await checkUserFormAccess(params).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.message === "HAS ACCESS") {
          checkFormDesign(formDesignId);
        } else if (response.data.message === "FORBIDDEN ACCESS") {
          dispatch({
            type: API_FAILURE,
            payload: {
              message: `User has no access to this form.`,
              data: response?.data?.object,
              type: "GET_FORM_DESIGN",
            },
          });
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  validateFormAccess:
    (formDesignId, navigate, formId, formName) => async (dispatch) => {
      try {
        const params = {
          formDesignId: formDesignId,
        };
        await checkUserFormAccess(params).then((response) => {
          if (!response) {
            throw {
              message: "Failed to send data. Please check your network.",
              type: "API/Network failure",
            };
          } else if (response.data.message === "HAS ACCESS") {
            navigate(`/forms-view/${formDesignId}/${formId}/${formName}`);
          } else if (response.data.message === "FORBIDDEN ACCESS") {
            dispatch({
              type: API_FAILURE,
              payload: {
                message: `User has no access to answer this form.`,
                data: response?.data?.object,
                type: "GET_FORM_DESIGN",
              },
            });
          } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
            throw {
              message: response.data.message,
            };
          }
        });
      } catch (error) {
        dispatch({ type: API_FAILURE, payload: error });
        console.error(error);
      }
    },
  syncOfflineAnswers: (userId, values) => async (dispatch) => {
    try {
      await syncFormAnswers(values).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to sync form answers.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
      dispatch({
        type: API_SUCCESS,
        payload: {
          message: `Successfully Sync Form Answers`,
          data: null,
          type: "SYNC_FORM_ANSWERS",
        },
      });
      del(`form-answers-user-${userId}`);
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  submitOfflineAnswers:
    (userId, handleAlertMessage, syncOfflineAnswers) => async () => {
      try {
        get(`form-answers-user-${userId}`).then((value) => {
          if (value) {
            syncOfflineAnswers(userId, value);
          } else {
            handleAlertMessage("Answers already synchronized.", "success");
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
  submitOfflineForms: (data, set, userId) => async () => {
    try {
      updateOfflineForms(data).then((response) => {
        if (!response) {
          console.error("Failed to send data. Please check your network.");
        } else {
          set(`downloaded-forms-${userId}`, response.data.object);
        }
      });
    } catch (e) {
      console.error(e);
    }
  },
  checkFormAccessZebra:
    (formDesignId, checkFormDesignZebra) => async (dispatch) => {
      try {
        const params = {
          formDesignId: formDesignId,
        };
        await checkUserFormAccess(params).then((response) => {
          if (!response) {
            throw {
              message: "Failed to send data. Please check your network.",
              type: "API/Network failure",
            };
          } else if (response.data.message === "HAS ACCESS") {
            checkFormDesignZebra(formDesignId);
          } else if (response.data.message === "FORBIDDEN ACCESS") {
            dispatch({
              type: API_FAILURE,
              payload: {
                message: `User has no access to this form.`,
                data: response?.data?.object,
                type: "GET_FORM_DESIGN_ZEBRA",
              },
            });
          } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
            throw {
              message: response.data.message,
            };
          }
        });
      } catch (error) {
        dispatch({ type: API_FAILURE, payload: error });
        console.error(error);
      }
    },
  checkFormDesignZebra: (data) => async (dispatch) => {
    try {
      await getFormDesign(data).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to get form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Succesfully Get Form Design`,
            data: response?.data?.object,
            type: "GET_FORM_DESIGN_ZEBRA",
          },
        });
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
};

export const formReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case API_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorType: "",
        errorMessage: "",
        success: false,
        successMessage: "",
        result: {},
      };
    case API_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorType: payload.type,
        errorMessage: payload.message,
        result: {},
      };
    case API_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        successMessage: payload.message,
        result: payload.data,
        resultType: payload.type,
      };
    case RESET_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        successMessage: "",
        errorMessage: "",
        result: payload?.data,
        resultType: payload?.type,
      };
    default:
      return state;
  }
};
