export const PageTitles = [
  {
    path: "/",
    title: "Forms",
  },
  {
    path: "/forms-view",
    title: "Forms View",
  },
];
