import { useParams } from "react-router-dom";
import { MainView } from "../../components";
import { FormsViewContextProvider } from "../../context";
import { FormsView } from "./FormsView";

export const MainFormsView = () => {
  const { formDesignId } = useParams();
  return (
    <MainView>
      <FormsViewContextProvider formDesignId={formDesignId}>
        <FormsView />
      </FormsViewContextProvider>
    </MainView>
  );
};
