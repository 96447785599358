import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Button,
} from "@mui/material";
import { Typography } from "antd";
import { CustomPagination } from "../pagination/CustomPagination";
import moment from "moment";
import { useInternetChecker } from "../../utilities";
import { useEffect, useState } from "react";
import { ButtonText, StandardButton } from "../styles/StandardButton";

const TableCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    textAlign: "left",
  },
  fontSize: "0.90rem",
  padding: "25px 30px",
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:last-child": {
    width: "150px",
  },
}));

const TableColumnCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    textAlign: "left",
    width: "100px",
  },
  fontSize: ".95rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  padding: "15px 30px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  "&:last-child": {
    width: "150px",
  },
}));

export const FormSubmissionTable = ({
  data,
  columnSchema,
  loading,
  pageNumber,
  handleChangePage,
  offlineFormAnswersPageNumber,
  offlineAnswerFirstPage,
  offlineAnswerLastPage,
  handleOfflineFormNextPage,
  handleOfflineFormPrevPage,
  selectedNavigation,
  formAccessData,
  requestFormAccess,
  formId,
  pagination,
}) => {
  const { internet } = useInternetChecker();
  const [newData, setNewData] = useState([]);

  console.log("pagination", pagination);

  const handleRequestFormAccess = () => {
    const userId = localStorage.getItem("id");
    requestFormAccess({ userId, formId });
  };

  const handleNextPage = () => {
    handleChangePage(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(pageNumber - 1);
  };

  useEffect(() => {
    if (internet) {
      setNewData(data?.content);
    } else {
      setNewData(data);
    }
  }, [internet, data]);

  const toProperCase = (str) => {
    if (typeof str !== "string") {
      return str;
    }
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const totalRange = () => {
    const start =
      data?.content?.length > 0
        ? pagination?.pageable?.pageNumber * pagination?.size + 1
        : 0;
    const end =
      data?.content?.length > 0
        ? Math.min(
            pagination?.pageable?.pageNumber * pagination?.size +
              pagination?.numberOfElements,
            pagination?.totalElements
          )
        : 0;
    const total = data?.content?.length > 0 ? pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();

  const reorderedColumnSchema = [
    ...columnSchema.filter((col) => col.id === "working_status"),
    ...columnSchema.filter((col) => col.id !== "working_status"),
  ];

  return (
    <>
      <TableContainer
        sx={{
          height: "100%",
          margin: "0% 2%",
          width: "100",
          overflowX: "auto",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {reorderedColumnSchema.map((column, index) => (
                <>
                  <TableColumnCellStyled key={column.id}>
                    {column.label}
                  </TableColumnCellStyled>
                </>
              ))}
              <TableColumnCellStyled>Submitted By</TableColumnCellStyled>
              <TableColumnCellStyled>Submission Date</TableColumnCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {newData &&
            newData.length > 0 &&
            (selectedNavigation === "MY_ANSWERS" ||
              (selectedNavigation === "ALL_ANSWERS" && formAccessData?.access))
              ? newData.map((submission, index) => (
                  <TableRow key={index}>
                    {reorderedColumnSchema.map((column) => (
                      <TableCellStyled key={column.id}>
                        {typeof submission.answer[column.id] === "object" ? (
                          <Grid container gap={1} justifyContent={"center"}>
                            {submission.answer[column.id]?.map(
                              (item, index) => (
                                <Grid item xs={12}>
                                  <a
                                    key={index}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.url}
                                  >
                                    {toProperCase(item.name)}
                                  </a>
                                </Grid>
                              )
                            )}
                          </Grid>
                        ) : column.id === "time" ||
                          column.id === "working_status" ? (
                          submission.answer[column.id]?.toUpperCase()
                        ) : (
                          toProperCase(submission.answer[column.id])
                        )}
                      </TableCellStyled>
                    ))}
                    <TableCellStyled key={index}>
                      {submission?.user?.fullname}
                    </TableCellStyled>
                    <TableCellStyled key={index}>
                      {moment(submission.answer.date).format(
                        "YYYY-MM-DD hh:mm A"
                      )}
                    </TableCellStyled>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {((!loading && formAccessData?.access && data?.content?.length === 0) ||
          (newData?.length === 0 && formAccessData?.access)) && (
          <Box
            minHeight={"40px"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}

        {!formAccessData?.access && selectedNavigation === "ALL_ANSWERS" && (
          <Grid
            justifyContent="center"
            alignItems="center"
            minHeight={"200px"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Grid>
              <Typography>
                Currently, the user has no access to these answers.
              </Typography>
            </Grid>
            <Grid>
              <StandardButton onClick={handleRequestFormAccess}>
                <ButtonText>Request Access</ButtonText>
              </StandardButton>
            </Grid>
          </Grid>
        )}
      </TableContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="5px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
        margin="0% 2% 0% 2%"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={
                internet
                  ? data?.pageable?.pageNumber + 1
                  : offlineFormAnswersPageNumber ?? 1
              }
              handleNextPage={
                internet ? handleNextPage : handleOfflineFormNextPage
              }
              handlePreviousPage={
                internet ? handlePreviousPage : handleOfflineFormPrevPage
              }
              disableFirst={internet ? data?.first : offlineAnswerFirstPage}
              disableNext={
                internet ? data?.last : offlineAnswerLastPage || loading
              }
              pagination={data}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
              Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
