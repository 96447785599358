import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useFormsData, useFormsFavoriteData, useSectionsData } from "../utilities";
import { formActions, formInitialState, formReducer } from "../reducer";
import { RESET_INITIAL_STATE } from "../constants";

const FormContext = createContext();

export const useFormContext = () => {
  return useContext(FormContext);
};

export const FormContextProvider = ({ children }) => {
  const userId = localStorage.getItem("id");
  const [syncLoading, setSyncLoading] = useState(false);

  const {
    forms,
    searchKeyword,
    formsPageNumber,
    formsPageSize,
    sort,
    // formsSection,
    optimizedSearchForms,
    mutateForms,
  } = useFormsData("form-designs/search-forms?");

  const { formsFavoriteData, mutateFormsFavoriteData } = useFormsFavoriteData(
    `forms/six-favorite-forms/${userId}`
  );

  useEffect(() => {
    mutateForms();
    dispatchForm({type: RESET_INITIAL_STATE})
  }, [
    forms,
    searchKeyword,
    formsPageNumber,
    formsPageSize,
    sort,
  ]);

  const { sectionsData } = useSectionsData("sections");

  const [formState, dispatchForm] = useReducer(formReducer, {
    ...formInitialState,
    pageForms: forms,
  });

  useEffect(() => {
    mutateForms();
  }, [formsPageNumber, formsPageSize, searchKeyword, sort]);

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    Object.keys(formActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          formActions[key](...args)(dispatchForm, stateRef.current);
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = formState;
  }, [formState]);

  const value = useMemo(
    () => ({
      forms,
      formsPageNumber,
      formsPageSize,
      searchKeyword,
      formState,
      dispatchForm,
      optimizedSearchForms,
      mutateForms,
      actions,
      formsFavoriteData,
      mutateFormsFavoriteData,
      sectionsData,
      // formsSection
      sort,
      syncLoading,
      setSyncLoading
    }),
    [
      forms,
      formsPageNumber,
      formsPageSize,
      searchKeyword,
      formState,
      dispatchForm,
      optimizedSearchForms,
      mutateForms,
      actions,
      formsFavoriteData,
      mutateFormsFavoriteData,
      sectionsData,
      // formsSection
      sort,
      syncLoading,
      setSyncLoading
    ]
  );

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};
