import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PageTitles } from "../../constants";
import { useInternetChecker } from "../../utilities";
const drawerWidth = 240;

const AppBars = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, internet }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  height: 60,
  background: internet ? "#009688" : theme.palette.accent.offline,
  color: theme.palette.common.white,
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down('sm')]: {
    height: 50,
    padding: theme.spacing(0, 1),
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  justifyContent: "start",
  alignItems: "center",
  display: "flex",
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: theme.spacing(1),
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.5),
  },
}));

export const AppBar = ({ handleDrawerOpen, open }) => {
  let location = useLocation();
  let navigate = useNavigate();
  const { internet } = useInternetChecker();

  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <AppBars position="fixed" open={open} internet={internet}>
      <Grid container>
        <FirstColumn item xs={6}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 0, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          {location?.pathname === "/forms-view" && (
            <IconButton color="inherit" onClick={handleNavigate}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography
            variant="h5"
            color="#fff"
            noWrap
            sx={{
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "16px",
                lg: "20px",
              },
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {PageTitles.map((item) =>
              item.path === location.pathname ? item.title : ""
            )}
          </Typography>
        </FirstColumn>
        <SecondColumn item xs={6}>
          <AccountCircleIcon style={{ fontSize: 30 }} />
          <Typography
            variant="body1"
            noWrap
            sx={{
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "16px",
                lg: "20px",
              },
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {localStorage.getItem("fullName")}
          </Typography>
        </SecondColumn>
      </Grid>
    </AppBars>
  );
};
