import { useEffect, useState } from "react";

export const useInternetChecker = () => {
  
  const [internet, setInternet] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setInternet(true);
    };

    const handleOffline = () => {
      setInternet(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { internet }
};
