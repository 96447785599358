import { useState } from "react";
import useScanDetection from "use-scan-detection";

export const useZebraScanner = () => {
  const [barcodeScan, setBarcodeScan] = useState(null);

  useScanDetection({
    onComplete: (barcode) => {
      const regBarcode = barcode.replace(
        /(Shift|Clear|Ctrl|Alt\d*|AltGr\d*|Meta|Hyper|Super|Win|Cmd|Fn)+([^A-Z:"{}\-_])?/g,
        "$2"
      );

      setBarcodeScan(regBarcode);
    },
  });

  return barcodeScan;
};
