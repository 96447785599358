/* eslint-disable no-throw-literal */
import { submitAnswer } from "../api";
import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  RESET_INITIAL_STATE,
} from "../constants";

export const formsViewInitialState = {
  loading: false,
  error: false,
  errorType: "",
  errorMessage: "",
  success: false,
  successMessage: "",
};

export const formsViewActions = {
  submitFormAnswers: (values) => async (dispatch) => {
    dispatch({ type: API_PENDING });

    try {
      await submitAnswer(values).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
      dispatch({
        type: API_SUCCESS,
        payload: "Succesfully Submitted Form Answer",
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  submitFormAnswersOffline: (set, userId, offlineFormAnswersFormView, isFormAlreadyAdded, values) => async (dispatch) => {
    dispatch({ type: API_PENDING });

    if (!isFormAlreadyAdded(values, offlineFormAnswersFormView)) {
      const updatedOfflineFormAnswers = [...offlineFormAnswersFormView, values];

      set(`form-answers-user-${userId}`, updatedOfflineFormAnswers);
      dispatch({
        type: API_SUCCESS,
        payload: "Succesfully Submitted Form Answer",
      });
    } else {
      dispatch({ type: API_FAILURE, payload: "This Form Answer is already added." });
    }
  }
};

export const formsViewReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case API_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorType: "",
        errorMessage: "",
        success: false,
        successMessage: "",
      };
    case API_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorType: payload.type,
        errorMessage: payload.message,
      };
    case API_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        successMessage: payload,
      };
    case RESET_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        successMessage: "",
        errorMessage: "",
      };
    default:
      return state;
  }
};
