import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ButtonText, MainDialog, StandardButton } from "../../../components";
import { useState } from "react";

export const FormsFilter = ({
  open,
  onClose,
  handleSubmitFilter,
  defaultSection,
  sections,
}) => {
  const [section, setSection] = useState(defaultSection);

  const handleChange = (event) => {
    setSection(event.target.value);
  };

  return (
    <MainDialog
      title={"Filter Form"}
      showDialog={open}
      handleClose={onClose}
      widthType={"xs"}
    >
      <Grid container padding={2} justifyContent={"center"}>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={section}
              onChange={handleChange}
              size="small"
              displayEmpty
            >
              {section === "" && <MenuItem value={""}>None</MenuItem>}
              {sections?.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <StandardButton
          onClick={() => {
            handleSubmitFilter(section);
          }}
        >
          <ButtonText>Submit</ButtonText>
        </StandardButton>
      </Grid>
    </MainDialog>
  );
};
