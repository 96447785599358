import { createContext, useContext, useState, useMemo } from "react";
import { useInternetChecker } from "../utilities";

const GlobalContext = createContext();

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export const GlobalContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { internet } = useInternetChecker();
  const [formsView, setFormsView] = useState(
    internet
      ? JSON.parse(localStorage.getItem("form-submission-schema"))
      : JSON.parse(localStorage.getItem("offline-form-schema"))
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleChangeFormsView = (value) => {
    setFormsView(value);
  };

  const value = useMemo(
    () => ({
      open,
      handleDrawerOpen,
      handleDrawerClose,
      formsView,
      handleChangeFormsView,
    }),
    [open, formsView]
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
