import { Autocomplete, TextField, styled } from "@mui/material";
import { useEffect, useState } from "react";

const AutoCompleteFilter = styled(Autocomplete)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  maxWidth: "120px",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
}));

export const PageSize = ({
  pageSizeValue,
  handleChange,
  internet,
  offlinePageSizeValue,
  handleChangeOffline,
}) => {
  const [pageSize, setPageSize] = useState(null);

  const handlePageSizeChange = (event, value) => {
    setPageSize(value);
    if (internet) {
      handleChange(value);
    } else {
      handleChangeOffline(value)
    }
  };

  const pageSizes = [10, 20, 30, 40, 50];


  useEffect(() => {
    if (internet) {
      setPageSize(pageSizeValue);
    } else {
      setPageSize(offlinePageSizeValue);
    }
  }, [internet]);

  return (
    <AutoCompleteFilter
      disablePortal
      disableClearable={true}
      id="combo-box-demo"
      options={pageSizes.map((item) => item)}
      value={pageSize}
      size="small"
      onChange={handlePageSizeChange}
      renderInput={(params) => <TextField {...params} label="Page Size" />}
    />
  );
};
