import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Tooltip,
} from "@mui/material";
import { FormsColumnSchema } from "../../constants";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import { CustomPagination } from "../pagination/CustomPagination";
import { Typography } from "antd";
import {
  theme,
  useDialog,
  useInternetChecker,
  useOfflineForm,
} from "../../utilities";
import { ToolTipDialog } from "../dialog/ToolTipDialog";
import { useEffect } from "react";
import { set, get } from "idb-keyval";

const TableCellStyled = styled(TableCell)(() => ({
  textAlign: "left",
  fontSize: "0.90rem",
  padding: "20px 30px",
  width: "auto",
  whiteSpace: "pre-wrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:last-child": {
    width: "150px",
  },
}));

const TableColumnCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    textAlign: "center",
  },
  fontSize: ".95rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  padding: "20px 30px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:last-child": {
    width: "150px",
  },
}));

export const FormsTable = ({
  data,
  handleChangePage,
  handleToggleFavorite,
  handleView,
  handleFormSubmission,
  handleSort,
  sorting,
  loading,
  formsPageNumber,
  pagination,
  handleAlertMessage,
  offlineFormPageNumber,
  handleOfflineFormPrevPage,
  handleOfflineFormNextPage,
  offlineFirstPage,
  offlineLastPage,
  offlineFavorites,
  offlineForms,
}) => {
  const { show, dialogData, handleAddDialogData, handleCloseDialog } =
    useDialog();

  const { internet } = useInternetChecker();
  const userId = localStorage.getItem("id");
  const sections = JSON.parse(localStorage.getItem("sections") ?? null);

  const handleNextPage = () => {
    handleChangePage(formsPageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(formsPageNumber - 1);
  };

  const handleAddSchema = (row) => {
    get(`downloaded-forms-${userId}`).then((downloadedForms) => {
      if (!isFormAlreadyDownloaded(row, downloadedForms)) {
        const updatedDownloadedForms = [...downloadedForms, row];
        set(`downloaded-forms-${userId}`, updatedDownloadedForms);
        handleAlertMessage("Form added successfully.", "success");
      } else {
        handleAlertMessage("This form is already added.", "error");
      }
    });
  };

  const isFormAlreadyDownloaded = (object) => {
    for (let i = 0; i < offlineForms?.length; i++) {
      if (offlineForms[i].formDesignId === object.formDesignId) {
        return true;
      }
    }
    return false;
  };

  const totalRange = () => {
    const start = data?.length > 0 ?  pagination?.pageable?.pageNumber * pagination?.size + 1 : 0;
    const end =data?.length > 0 ?  Math.min(
      pagination?.pageable?.pageNumber * pagination?.size +
        pagination?.numberOfElements,
      pagination?.totalElements
    ) : 0;
    const total = data?.length > 0 ?  pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();

  return (
    <>
      <TableContainer
        sx={{
          // height: "100%",
          margin: "2% 2% 0% 2%",
          width: "100",
          overflowX: "auto",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {FormsColumnSchema.map((column) => (
                <TableColumnCellStyled key={column.id}>
                  {column.label}
                  {column.hasSorting && (
                    <IconButton
                      onClick={() => {
                        handleSort(column.id);
                      }}
                      sx={{ width: 20, height: 20 }}
                    >
                      {sorting.column === column.id &&
                      sorting.order === "ASC" ? (
                        <ArrowUpwardIcon
                          sx={{ color: "#444444", fontSize: "18px" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{ color: "#444444", fontSize: "18px" }}
                        />
                      )}
                    </IconButton>
                  )}
                </TableColumnCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index}>
                <TableCellStyled sx={{ textTransform: "capitalize" }}>
                  <Grid container>
                    <Grid item xs={12} textAlign="center">
                      <Tooltip title="Add to Favorites" placement="top">
                        <IconButton
                          onClick={() => {
                            if (internet) handleToggleFavorite(row);
                            else
                              handleAlertMessage(
                                "Not available offline.",
                                "error"
                              );
                          }}
                        >
                          {row.favorite ? (
                            <StarIcon
                              sx={{ color: "#444444", fontSize: "25px" }}
                            />
                          ) : (
                            <StarOutlineIcon
                              sx={{ color: "#444444", fontSize: "25px" }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      {row.filerSections
                        ?.filter((filer) =>
                          sections.some(
                            (item) => item.section.name === filer.name
                          )
                        )
                        .map((filteredItem) => filteredItem.name)
                        .join(", ")}
                    </Grid>
                  </Grid>
                </TableCellStyled>
                <TableCellStyled>{row.name}</TableCellStyled>
                <TableCellStyled>
                  <Grid container gap={1} justifyContent={"left"}>
                    {row.tags?.map((item, index) => {
                      return (
                        <Chip
                          key={index}
                          sx={{
                            color: internet ? "#000" : "#FFF",
                            fontWeight: "normal",
                            background: internet
                              ? `rgba(211, 211, 211, 0.6)`
                              : theme.palette.accent.offline,
                          }}
                          label={item}
                        />
                      );
                    })}
                  </Grid>
                </TableCellStyled>
                <TableCellStyled>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    wrap="wrap"
                  >
                    <Grid item xs={5}>
                      <Tooltip title="View Form Submission" placement="top">
                        <IconButton onClick={() => handleFormSubmission(row)}>
                          <VisibilityIcon
                            sx={{ color: "#444444", fontSize: "25px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={5}>
                      <Tooltip title="Submit new Form Answer" placement="top">
                        <IconButton onClick={() => handleView(row)}>
                          <SendIcon
                            sx={{ color: "#444444", fontSize: "25px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {internet ? (
                      <Grid item xs={5}>
                        <Tooltip title="Download Form" placement="top">
                          <IconButton onClick={() => handleAddSchema(row)}>
                            <DownloadIcon
                              sx={{
                                color: isFormAlreadyDownloaded(row)
                                  ? theme.palette.accent.secondary
                                  : "#444444",
                                fontSize: "25px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ) : null}
                    <Grid item xs={5}>
                      <Tooltip title="Form Additional Info" placement="top">
                        <IconButton onClick={() => handleAddDialogData(row)}>
                          <InfoIcon
                            sx={{ color: "#444444", fontSize: "25px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!loading && data.length === 0 && (
          <Box marginTop={2}>
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}
        {dialogData && (
          <ToolTipDialog
            data={dialogData}
            open={show}
            handleClose={handleCloseDialog}
            internet={internet}
          />
        )}
      </TableContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="0px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
        margin= "0% 2% 0% 2%"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={
                internet ? formsPageNumber ?? 1 : offlineFormPageNumber ?? 1
              }
              handleNextPage={
                internet ? handleNextPage : handleOfflineFormNextPage
              }
              handlePreviousPage={
                internet ? handlePreviousPage : handleOfflineFormPrevPage
              }
              disableFirst={
                internet
                  ? pagination?.length === 0 || pagination?.first
                  : offlineFirstPage
              }
              disableNext={
                internet
                  ? pagination?.length === 0 || pagination?.last
                  : offlineLastPage || loading
              }
              pagination={pagination}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
              Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
