export const FormsColumnSchema = [
  { id: "section", label: "Section", hasSorting: false },
  { id: "tf.name", label: "Form Name", hasSorting: false },
  { id: "tags ", label: "Tags ", hasSorting: false },
  { id: "action ", label: "Action ", hasSorting: false },
];

export const FormsTableRowData = [
  {
    id: 1,
    section: "HR",
    formName: "Meeting Room Reservation",
    tags: [
      { id: 1, name: "HR" },
      { id: 2, name: "Room" },
    ],
    isFavorite: false,
  },
  {
    id: 2,
    section: "Painting",
    formName: "Water Rinse 5 Operation Checksheet (EDP2)",
    tags: [
      { id: 1, name: "ED2" },
      { id: 2, name: "Operation" },
      { id: 3, name: "Water" },
      { id: 4, name: "Rinse" },
    ],
    isFavorite: true,
  },
  {
    id: 3,
    section: "Painting",
    formName: "Water Rinse 2 Operation Checksheet (EDP2)",
    tags: [
      { id: 1, name: "ED2" },
      { id: 2, name: "Operation" },
      { id: 3, name: "Water" },
      { id: 4, name: "Rinse" },
    ],
    isFavorite: true,
  },
  {
    id: 4,
    section: "HR",
    formName: "Feedback Room",
    tags: [
      { id: 1, name: "HR" },
      { id: 2, name: "HR Form" },
    ],
    isFavorite: false,
  },
  {
    id: 5,
    section: "Accounting",
    formName: "Travel Reimbursement",
    tags: [
      { id: 1, name: "Travel" },
      { id: 2, name: "Expense" },
    ],
    isFavorite: false,
  },
  {
    id: 6,
    section: "Painting",
    formName: "DI Dip Rinse Operation Checksheet (EDP2)",
    tags: [
      { id: 1, name: "ED2" },
      { id: 2, name: "Operation" },
      { id: 3, name: "Rise" },
      { id: 4, name: "Dip" },
    ],
    isFavorite: true,
  },
  {
    id: 7,
    section: "Accounting",
    formName: "Travel Reimbursement",
    tags: [
      { id: 1, name: "Travel" },
      { id: 2, name: "Expense" },
    ],
    isFavorite: false,
  },
];
