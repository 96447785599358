import {
  Snackbar,
  Backdrop,
  CircularProgress,
  Grid,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const PageContainer = styled(Grid)(() => ({
  flexGrow: 1,
  overflow: "hidden",
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: "#fff",
}));

export const PageComponent = ({
  children,
  showAlert,
  handleCloseAlert,
  alertSeverity,
  alertMessage,
  isLoading,
  ...props
}) => {
  return (
    <PageContainer {...props}>
      {children}
      <StyledBackdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ zIndex: 10000 }}
        open={showAlert}
        onClose={handleCloseAlert}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleCloseAlert}
          elevation={6}
          variant="filled"
          severity={alertSeverity}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};
