import { useState } from "react";

export const useAlternateData = () => {
  const [formData, setFormData] = useState(null);
  const [favoriteFormData, setFavoriteFormData] = useState(null);
  const [formAnswerData, setFormAnswerData] = useState(null);

  const handleAddOnlineData = (data) => {
    setFormData(data);
  };

  const handleAddOfflineData = (data) => {
    setFormData(data);
  };

  const handleAddOnlineFavoriteData = (data) => {
    setFavoriteFormData(data);
  };

  const handleAddOfflineFavoriteData = (data) => {
    setFavoriteFormData(data);
  };

  const handleAddOnlineFormAnswersData = (data) => {
    setFormAnswerData(data)
  }

  const handleAddOfflineFormAnswersData = (data) => {
    setFormAnswerData(data);
  }

  return {
    formData,
    favoriteFormData,
    formAnswerData,
    handleAddOnlineData,
    handleAddOfflineData,
    handleAddOnlineFavoriteData,
    handleAddOfflineFavoriteData,
    handleAddOnlineFormAnswersData,
    handleAddOfflineFormAnswersData
  };
};
