import React, { useState, useEffect } from "react";
import { Select as FormilySelect } from "@formily/antd";
import { Select as UISelect } from "antd";
import { createBehavior, createResource } from "@designable/core";
import { createFieldSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import { getPropertyByStringPath } from "../../../../utilities";

export const Select = FormilySelect;

Select.Behavior = createBehavior({
  name: "Select",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Select",
  designerProps: {
    propsSchema: createFieldSchema(AllSchemas.Select),
  },
  designerLocales: AllLocales.Select,
});

Select.Resource = createResource({
  icon: "SelectSource",
  elements: [
    {
      componentName: "Field",
      props: {
        title: "Select",
        "x-decorator": "FormItem",
        "x-component": "Select",
      },
    },
  ],
});

export const CreatableSelect = ({
  options = [],
  apiCall,
  getData,
  optionKey,
  renderValue,
  getOption,
  creatable = true,
  disabled = false,
  value,
  hasEmpty = false,
  refetchValueSideEffect,
  ...props
}) => {
  const [selectValue, setSelectValue] = useState(value);
  const [inputValue, setInputValue] = useState();
  const [creating, setCreating] = useState(false);
  const [selections, setSelections] = useState(options);

  const NEW_ITEM = "CREATE_NEW_ITEM";

  const handleChange = async (value) => {
    if (value === NEW_ITEM && creatable) {
      setCreating(true);
      setSelectValue(inputValue);

      const response = await apiCall(inputValue);

      setSelections([...selections, { ...response }]);

      props.onChange(getPropertyByStringPath(response, optionKey));
      setInputValue("");
      setCreating(false);
    } else {
      setSelectValue(value);
      props.onChange(value);
    }
  };

  const getThisOption = (option) => {
    if (getOption) return getOption(option);
    else return getPropertyByStringPath(option, optionKey);
  };

  const renderOptionLabel = (option) => {
    if (renderValue) {
      return renderValue(option);
    } else {
      return getPropertyByStringPath(option, optionKey);
    }
  };

  useEffect(() => {
    const getAsyncData = async () => {
      if (getData) {
        setCreating(true);
        await getData().then((data) => {
          setSelections(data);
          setCreating(false);
        });
      }
    };

    if (!disabled) {
      getAsyncData();
    }
  }, [disabled, refetchValueSideEffect]);

  return (
    <UISelect
      value={selectValue}
      loading={creating}
      disabled={creating || disabled}
      showSearch
      optionFilterProp="children"
      onChange={handleChange}
      onSearch={(e) => {
        setInputValue(e);
      }}
    >
      {inputValue &&
        creatable &&
        selections.filter((o) => o === inputValue).length === 0 && (
          <UISelect.Option key={inputValue} value={NEW_ITEM}>
            {"Add " + inputValue}
          </UISelect.Option>
        )}
      {!inputValue && hasEmpty && <UISelect.Option>Public</UISelect.Option>}
      {selections.map((o) => (
        <UISelect.Option key={o.id} value={getThisOption(o)}>
          {renderOptionLabel(o)}
        </UISelect.Option>
      ))}
    </UISelect>
  );
};
