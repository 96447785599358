import * as React from "react";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { FormLabel, Grid, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";

export const CustomDateRangePicker = ({
  startDate,
  endDate,
  handleChangeEndDate,
  handleChangeStartDate,
  error,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <FormLabel component="legend" style={{ color: "black" }}>
        Date Submitted
      </FormLabel>
      <Grid
        container
        justifyContent="space-between"
        columnGap={1}
        marginTop={1.5}
      >
        <Grid item xs={5.8}>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: { size: "small", error: false, fullWidth: true },
            }}
            label="Start Date"
            value={startDate ? dayjs(startDate) : null}
            onChange={(value) => {
              handleChangeStartDate(value);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>

        <Grid item xs={5.8}>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: { size: "small", error: false, fullWidth: true },
            }}
            label="End Date"
            value={endDate ? dayjs(endDate) : null}
            onChange={(value) => {
              handleChangeEndDate(value);
            }}
          />
        </Grid>
      </Grid>
      {error && (
        <Typography color="error" fontSize="12px" marginTop={0.5}>
          {error}
        </Typography>
      )}
    </LocalizationProvider>
  );
};
