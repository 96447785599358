import { useParams } from "react-router-dom";
import { MainView } from "../../components";
import { FormSubmissionContextProvider } from "../../context";
import { useAlert, useInternetChecker } from "../../utilities";
import { FormAnswer } from "./form-answer/FormAnswer";

export const FormSubmission = () => {
  const { handleAlertMessage, ...alertRest } = useAlert();
  const { formDesignId, formName, formVersion } = useParams();
  const { internet } = useInternetChecker();
  const decodedFormName = decodeURIComponent(formName);
  const form = internet
    ? JSON.parse(localStorage.getItem("form-submission-schema")) ??
      JSON.parse(localStorage.getItem("offline-form-submission-schema"))
    : JSON.parse(localStorage.getItem("offline-form-submission-schema"));

  return (
    <MainView>
      <FormSubmissionContextProvider
        designId={formDesignId}
        formVersion={formVersion}
        formId={form.id}
      >
        <FormAnswer formName={decodedFormName} formId={form.id} />
      </FormSubmissionContextProvider>
    </MainView>
  );
};
