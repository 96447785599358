import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { QrReader } from "react-qr-reader";
import {
  ButtonText,
  Favorites,
  FormsTable,
  MainView,
  PageComponent,
  PageSize,
  SearchField,
  StandardButton,
  ZebraScanner,
} from "../../components";
import { API_PENDING, RESET_INITIAL_STATE } from "../../constants";
import { useFormContext, useGlobalContext } from "../../context";
import {
  useAlert,
  useForm,
  useOfflineForm,
  useAlternateData,
  useInternetChecker,
  useZebraScanner,
} from "../../utilities";
import { FormsFilter } from "./forms-filter/FormsFilter";
import { useNavigate } from "react-router-dom";
import { get, set } from "idb-keyval";

export const QRButton = styled(Button)(({ theme, internet }) => ({
  background: internet ? "#1B9A92" : theme.palette.accent.offline,
  color: "#fff",
  padding: "0.5rem 1rem",
  textTransform: "capitalize",
  whiteSpace: "nowrap",
  overflow: "hidden",
  "&:hover": {
    background: "#1B9A92",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0.25rem 0.5rem",
    fontSize: "0.875rem",
  },
  [theme.breakpoints.up("md")]: {
    padding: "0.5rem 1rem",
    fontSize: "1rem",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "0.75rem 1.5rem",
    fontSize: "1.125rem",
  },
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1% 3% 0 3%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "1% 1% 0 1%",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto",
    minWidth: "100px",
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
  },
}));

const QRScannerModal = styled("div")(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9999,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const QrReaderWrapper = styled("div")(() => ({
  width: "70%",
  maxWidth: "400px",
  height: "auto",
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  position: "relative",
}));

const QRCodeScanner = ({
  showScanner,
  handleCloseScanner,
  handleScan,
  qrCodeData,
}) => {
  const [cameraPermission, setCameraPermission] = useState(null);

  const handleCameraPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });

      if (permissionStatus.state === "granted") {
        setCameraPermission(true);
      } else if (permissionStatus.state === "prompt") {
        // Permission is not granted, but it can be requested
        await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraPermission(true);
      } else {
        setCameraPermission(false);
      }
    } catch (error) {
      setCameraPermission(false);
      console.error("Error checking camera permission:", error);
    }
  };

  useEffect(() => {
    if (showScanner) {
      handleCameraPermission();
    }
  }, [showScanner]);
  return (
    <QRScannerModal>
      <QrReaderWrapper>
        <QrReader
          delay={300}
          onError={(err) => {
            console.error(err);
            handleCloseScanner(); // Close scanner on error
          }}
          onResult={handleScan}
          style={{ width: "100%", height: "auto" }}
        />
        <StandardButton onClick={handleCloseScanner}>
          <ButtonText>Close</ButtonText>
        </StandardButton>
      </QrReaderWrapper>
    </QRScannerModal>
  );
};

export const Forms = () => {
  let navigate = useNavigate();
  const {
    forms,
    formsPageNumber,
    formsPageSize,
    searchKeyword,
    formState,
    dispatchForm,
    optimizedSearchForms,
    mutateForms,
    actions: {
      updateFavorite,
      checkFormDesign,
      checkFormAccess,
      validateFormAccess,
      checkFormDesignOffline,
      syncOfflineAnswers,
      submitOfflineAnswers,
      submitOfflineForms,
      checkFormAccessZebra,
      checkFormDesignZebra,
      checkFormDesignOfflineZebra,
    },
    formsFavoriteData,
    mutateFormsFavoriteData,
    sectionsData,
    sort,
    syncLoading,
    setSyncLoading,
  } = useFormContext();

  const { handleChangeFormsView } = useGlobalContext();
  const scannedQR = useZebraScanner();

  const userId = localStorage.getItem("id");

  const { handleAlertMessage, ...alertRest } = useAlert();
  const { formData, handleAddOfflineData, handleAddOnlineData } =
    useAlternateData();
  const { internet } = useInternetChecker();
  const {
    offlineFormsData,
    offlineFavoriteForms,
    offlineFormPageNumber,
    handleOfflineFormNextPage,
    handleOfflineFormPrevPage,
    offlinePageSize,
    handleOfflineChangePage,
    offlineIsFirstPage,
    offlineIsLastPage,
  } = useOfflineForm(userId, internet);

  const [qrCodeValues, setQRCodeValues] = useState(null);

  const {
    showModal: showFilter,
    handleCloseModal: handleCloseFilter,
    handleAddData: handleShowFilter,
  } = useForm();

  const handleSuccess = () => {
    if (formState?.resultType === "UPDATE_FORM_FAVORITE") {
      mutateForms();
      mutateFormsFavoriteData();
    }

    if (formState?.resultType === "GET_FORM_DESIGN") {
      if (internet) {
        navigate(
          `/forms-view/${formState.result.id}/${formState.result.form.id}/${formState.result.form.name}`
        );
        localStorage.setItem(
          "form_initial_answer",
          JSON.stringify(qrCodeValues)
        );
      } else {
        const offlineFormData = extractFormOfflineByFormDesignId(
          offlineFormsData,
          qrCodeValues.formDesignId
        );

        if (offlineFormData) {
          navigate(
            `/forms-view/${qrCodeValues.formDesignId}/${offlineFormData.id}/${offlineFormData.name}`,
            {
              state: offlineFormData,
            }
          );
          localStorage.setItem(
            "offline_form_initial_answer",
            JSON.stringify(qrCodeValues)
          );
        } else {
          handleAlertMessage("Form not available offline.", "error");
        }
      }
    }

    if (formState?.resultType === "GET_FORM_DESIGN_ZEBRA") {
      if (internet) {
        navigate(
          `/forms-view/${formState.result.id}/${formState.result.form.id}/${formState.result.form.name}`
        );
        localStorage.setItem(
          "form_initial_answer",
          JSON.stringify(JSON.parse(scannedQR))
        );
      } else {
        const offlineFormData = extractFormOfflineByFormDesignId(
          offlineFormsData,
          JSON.parse(scannedQR).formDesignId
        );

        if (offlineFormData) {
          navigate(
            `/forms-view/${JSON.parse(scannedQR).formDesignId}/${
              offlineFormData.id
            }/${offlineFormData.name}`,
            {
              state: offlineFormData,
            }
          );
          localStorage.setItem(
            "offline_form_initial_answer",
            JSON.stringify(JSON.parse(scannedQR))
          );
        } else {
          handleAlertMessage("Form not available offline.", "error");
        }
      }
    }

    if (formState?.resultType === "SYNC_FORM_ANSWERS") {
      handleAlertMessage(formState?.successMessage, "success");
    }
    handleAlertMessage(formState.successMessage, "success");
    dispatchForm({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    mutateForms();
    mutateFormsFavoriteData();
    handleAlertMessage(formState.errorMessage, "error");
    dispatchForm({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (formState.successMessage) {
      handleSuccess();
    } else if (formState.errorMessage) {
      handleError();
    }
  }, [formState.success, formState.error]);

  useEffect(() => {
    if (forms) {
      dispatchForm({ type: RESET_INITIAL_STATE });
    }
  }, [forms]);

  const [sorting, setSorting] = useState({ column: "", order: "" });
  const [showScanner, setShowScanner] = useState(false);
  const [qrCodeData, setQRCodeData] = useState("");

  const handleChangeSort = (column) => {
    const newOrder =
      sorting.column === column && sorting.order === "ASC" ? "DESC" : "ASC";

    setSorting({ column: column, order: newOrder });
    const sortValue = `${column},${newOrder}`;
    optimizedSearchForms(
      searchKeyword,
      formsPageNumber,
      formsPageSize,
      sortValue
    );
  };

  const handleChangePage = (pageNumber) => {
    dispatchForm({ type: API_PENDING });
    optimizedSearchForms(searchKeyword, pageNumber, formsPageSize, sort);
  };

  const handleSearchKeyword = (keyword) => {
    optimizedSearchForms(keyword, 1, formsPageSize, sort);
  };

  const handleToggleFavorite = (row) => {
    updateFavorite(row);
  };

  const handleSubmitFilter = (section) => {
    optimizedSearchForms(searchKeyword, 1, formsPageSize, sort);
    handleCloseFilter();
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchForms(searchKeyword, 1, pageSize, sort);
  };

  const handleClearFilter = () => {
    optimizedSearchForms("", formsPageNumber, formsPageSize, "tf.name,ASC");
  };

  const handleFormsView = (row) => {
    const encodedFormName = encodeURIComponent(row.name);
    if (internet) {
      validateFormAccess(row.formDesignId, navigate, row.id, encodedFormName);
    } else {
      localStorage.setItem("offline-form-schema", JSON.stringify(row));
      handleChangeFormsView(row);
      navigate(`/forms-view/${row.formDesignId}/${row.id}/${encodedFormName}`, {
        state: row,
      });
    }
  };

  const handleClickFavorite = (row) => {
    if (!row) return;

    const encodedFormName = encodeURIComponent(row.name);
    handleChangeFormsView(row);

    if (internet) {
      validateFormAccess(row.formDesignId, navigate, row.id, encodedFormName);
    } else {
      navigate(`/forms-view/${row.formDesignId}/${row.id}/${encodedFormName}`, {
        state: row,
      });
    }
  };

  const handleSyncAnswers = async () => {
    setSyncLoading(true);

    const downloadedForms = await get(`downloaded-forms-${userId}`);
    if (downloadedForms) {
      submitOfflineForms(downloadedForms, set, userId);
    }

    submitOfflineAnswers(userId, handleAlertMessage, syncOfflineAnswers);
    setSyncLoading(false);
  };

  const handleFormsSubmission = (row) => {
    const encodedFormName = encodeURIComponent(row.name);
    if (internet) {
      localStorage.setItem("form-submission-schema", JSON.stringify(row));
      navigate(
        `/form-submissions/${row.formDesignId}/${encodedFormName}/${row.version}/${row.id}`,
        { state: row }
      );
    } else {
      localStorage.setItem(
        "offline-form-submission-schema",
        JSON.stringify(row)
      );
      navigate(
        `/form-submissions/${row.formDesignId}/${encodedFormName}/${row.version}/${row.id}`,
        { state: row }
      );
    }
  };

  const extractFormDesignId = (data) => {
    let qrCodeValues;

    try {
      qrCodeValues = JSON.parse(data);
    } catch (error) {
      return null;
    }
    if (Object.keys(qrCodeValues).length > 0) {
      return qrCodeValues?.formDesignId ? qrCodeValues : null;
    }
    return null;
  };

  const extractFormOfflineByFormDesignId = (offlineFormData, formDesignId) => {
    // Find the object with the matching formDesignId
    const matchedFormData = offlineFormData.find(
      (item) => item.formDesignId === formDesignId
    );

    if (matchedFormData) {
      return matchedFormData;
    } else {
      handleAlertMessage("Form not available offline", "error");
    }

    return null;
  };

  useEffect(() => {
    const isValidJSON = (str) => {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    };
  
    if (scannedQR !== null && isValidJSON(scannedQR)) {
      let stringifiedQR = JSON.stringify(JSON.parse(scannedQR));
  
      if (stringifiedQR.startsWith("{") && stringifiedQR.endsWith("}")) {
        if (internet) {
          checkFormAccessZebra(JSON.parse(scannedQR).formDesignId, checkFormDesignZebra);
        } else {
          const offlineFormData = extractFormOfflineByFormDesignId(
            offlineFormsData,
            JSON.parse(scannedQR).formDesignId
          );
  
          checkFormDesignOfflineZebra(offlineFormData);
        }
      }
    } else {
      console.log("Invalid or null QR data");
    }
  }, [scannedQR]);

  console.log("scanned qr ", scannedQR)

  const handleScan = async (data) => {
    if (data && data.text) {
      setQRCodeData(data.text);
      // Extract FormDesignId from the scanned QR code data

      const qrValues = extractFormDesignId(data.text);
      if (qrValues) {
        if (internet) {
          handleCloseScanner();
          setQRCodeValues(qrValues);
          checkFormAccess(qrValues.formDesignId, checkFormDesign);
        } else {
          handleCloseScanner();
          setQRCodeValues(qrValues);

          const offlineFormData = extractFormOfflineByFormDesignId(
            offlineFormsData,
            qrValues.formDesignId
          );

          checkFormDesignOffline(offlineFormData);
        }
      } else {
        handleAlertMessage("Form is not existing or not valid", "error");
      }
    }
  };

  const handleCloseScanner = () => {
    setShowScanner(false);
    setQRCodeData("");
  };

  useEffect(() => {
    if (internet && offlineFavoriteForms?.length < 1) {
      set(`favorite-forms-${userId}`, formsFavoriteData);
    }
    if (internet && offlineFormsData?.length < 1) {
      set(`downloaded-forms-${userId}`, []);
    }
    if (internet && formsFavoriteData !== offlineFavoriteForms) {
      set(`favorite-forms-${userId}`, formsFavoriteData);
    }
  }, [internet, formsFavoriteData, offlineFormsData, offlineFavoriteForms]);

  useEffect(() => {
    if (internet) {
      handleAddOnlineData(forms?.content);
    } else {
      handleAddOfflineData(offlineFormsData);
    }
  }, [internet, forms, offlineFormsData]);

  return (
    <MainView>
      <PageComponent
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
        isLoading={formState.loading}
      >
        <Favorites
          data={internet ? formsFavoriteData : offlineFavoriteForms}
          handleClickFavorite={handleClickFavorite}
          internet={internet}
        />
        <GridContainer container gap={2}>
          <FirstColumn
            gap={2}
            sx={{
              marginLeft: { xs: "0px", sm: "-5px", md: "-10px", lg: "-15px" },
            }}
          >
            {internet ? (
              <SearchField
                search={handleSearchKeyword}
                keyword={searchKeyword}
              />
            ) : null}
            <PageSize
              pageSizeValue={formsPageSize}
              handleChange={handleChangePageSize}
              internet={internet}
              offlinePageSizeValue={offlinePageSize}
              handleChangeOffline={handleOfflineChangePage}
            />
          </FirstColumn>
          <SecondColumn
            gap={1}
            sx={{
              marginRight: { xs: "0px", sm: "-2.5px", md: "-5px", lg: "-10px" },
            }}
          >
            {internet ? (
              <StandardButton internet={internet} onClick={handleSyncAnswers}>
                <ButtonText>
                  {syncLoading ? (
                    <CircularProgress size={25} sx={{ color: "white" }} />
                  ) : (
                    "Sync"
                  )}
                </ButtonText>
              </StandardButton>
            ) : null}
            {/* QR code scanner button */}
            <QRButton internet={internet} onClick={() => setShowScanner(true)}>
              <ButtonText>QR</ButtonText>
            </QRButton>
            {/* QR code scanner modal */}
            {showScanner && (
              <QRCodeScanner
                showScanner={showScanner}
                handleCloseScanner={handleCloseScanner}
                handleScan={handleScan}
                qrCodeData={qrCodeData}
              />
            )}
          </SecondColumn>
        </GridContainer>
        <Grid container>
          <FormsTable
            data={formData ?? []}
            sorting={sorting}
            formsPageNumber={formsPageNumber}
            pagination={forms}
            loading={formState.loading}
            handleSort={handleChangeSort}
            handleChangePage={handleChangePage}
            handleToggleFavorite={handleToggleFavorite}
            handleView={handleFormsView}
            handleFormSubmission={handleFormsSubmission}
            handleAlertMessage={handleAlertMessage}
            offlineFormPageNumber={offlineFormPageNumber}
            handleOfflineFormNextPage={handleOfflineFormNextPage}
            handleOfflineFormPrevPage={handleOfflineFormPrevPage}
            offlineFirstPage={offlineIsFirstPage}
            offlineLastPage={offlineIsLastPage}
            handleOpenDialog
            offlineFavorites={offlineFavoriteForms}
            offlineForms={offlineFormsData}
          />
        </Grid>
        {showFilter && (
          <FormsFilter
            open={showFilter}
            onClose={handleCloseFilter}
            handleSubmitFilter={handleSubmitFilter}
            sections={sectionsData}
          />
        )}
      </PageComponent>
    </MainView>
  );
};
